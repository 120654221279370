import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import autosize from "autosize";
import "./WordEdit.css";

export default function WordEdit({
  localSelectedWord,
  handleWordAddClick,
  clearTranslationPanelSelectedWordContent,
}) {
  const [editedWordValue, setEditedWordValue] = useState("");

  // Whenever localSelectedWord.translation changes, update the state
  useEffect(() => {
    setEditedWordValue(localSelectedWord.translation || "");
  }, [localSelectedWord.translation]);

  async function handleWordEditSave(e) {
    e.preventDefault();

    // Blur the currently focused element to hide the keyboard
    // NOTE: another approach would be to just wait for the word to finish saving - it will take time anyway
    // otherwise there's no place to show the error message in
    if (document.activeElement) {
      document.activeElement.blur();
    }

    let delayTime = 0;
    if (isMobileSafari()) {
      delayTime = 800;
    } else if (isMobileBrowser()) {
      delayTime = 200;
    }

    // Delay the save operation to allow the virtual keyboard to hide
    setTimeout(() => {
      console.log("Edited translation: ", editedWordValue);
      handleWordAddClick(e, editedWordValue);
      clearTranslationPanelSelectedWordContent();
    }, delayTime); // You may need to increase this value if the keyboard takes longer to hide
  }

  function handleWordEditChange(e) {
    setEditedWordValue(e.target.value);
    autosize(e.target);
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      handleWordEditSave(e);
    }
  }

  function isMobileSafari() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // This is an iOS device, now check if it's Safari
      return (
        /WebKit/.test(userAgent) &&
        !/CriOS/.test(userAgent) &&
        !/FxiOS/.test(userAgent) &&
        !/OPiOS/.test(userAgent)
      );
    }
    return false;
  }

  function isMobileBrowser() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Check for mobile device patterns in the user agent string
    return /iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini/.test(
      userAgent
    );
  }

  return (
    <div className="d-flex flex-column align-items-end">
      <textarea
        className="form-control autosize-textarea"
        id="customTranslationTextArea"
        wrap="soft"
        placeholder="Enter your translation here"
        onChange={handleWordEditChange}
        onKeyDown={handleKeyDown}
        value={editedWordValue}
        maxLength={50}
        rows={1}
      ></textarea>
      <Button
        className="mt-2 mx-1"
        variant="primary"
        onClick={(e) => {
          e.stopPropagation();
          handleWordEditSave(e);
        }}
        size="sm"
      >
        Save
      </Button>
    </div>
  );
}
