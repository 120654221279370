import React from "react";
import {
  Card,
  CardGroup,
} from "react-bootstrap";

import AppMetaDataContext from "../context/AppMetaDataContext";

function Benefits() {
  const metaData = React.useContext(AppMetaDataContext);

    return (
      <div>
        <h1
          className="text-center"
          style={{ marginBottom: "7%", marginTop: "10%" }}
        >
          Benefits
        </h1>

        <CardGroup style={{ marginBottom: "0%" }}>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>
                Learn about the world while you learn a language
              </Card.Title>
              <Card.Text>
                {metaData.appName} lets you read real news articles from around the world. The same ones the native speakers read. You'll learn about the world while you learn a language.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>Learn the lingo you need</Card.Title>
              <Card.Text>
                The vocabulary in politics is very different from the one used in sports or in technology. By choosing the topics you're interested in, you'll champion
                the vocabulary you actually need.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>

        <CardGroup style={{ marginBottom: "13%" }}>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>No nonsense sentences</Card.Title>
              <Card.Text>
                Duolingo sometimes teaches you sentences that don't make sense.
                "The cow opened its favorite book" and "The cat wrote a letter
                in Japanese" are actual sentences you'll "learn" in Duolingo.
                Ever seen a cat write a letter in Japanese?
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>
                Give meaning back to your language learning
              </Card.Title>
              <Card.Text>
                Most sentences in language learning apps and courses do make sense. But there's no
                immediate use for them. I'm not trying to order wine in a
                restaurant while I'm on my couch looking at my phone or in a language class. With{" "}
                {metaData.appName} you'll learn the vocabulary you actually
                need.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
        </div>
    );
}

export default Benefits;
