import React from "react";
import { Card, CardGroup, Image } from "react-bootstrap";
import ScreenshotReader from "../screenshot-reader-1.png";

function Explanations() {
  const imageContainerStyle = {
    position: "relative",
    display: "inline-block", // This makes the container fit the image size
  };

  return (
    <div>
      <h1
        className="text-center"
        style={{ marginBottom: "5%", marginTop: "10%" }}
      >
        Vocabulary Tracking & AI Explanations
      </h1>
      <h4 className="text-center pb-4">
        It's like Kindle for language learning
      </h4>

      <div className="d-flex justify-content-center mt-5 mb-4">
        <div style={imageContainerStyle}>
          <Image
            src={ScreenshotReader}
            fluid
            style={{
              width: "100%",
              maxWidth: "900px",
              border: "1px solid lightgray",
              borderRadius: "10px",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "150px", // Adjust the height to control the fade area
              backgroundImage:
                "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
            }}
          />
        </div>
      </div>

      <CardGroup style={{ marginBottom: "0%" }}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>Automatic vocabulary and progress tracking</Card.Title>
            <Card.Text>
              The new words are marked in blue. Every time you turn the page,
              the new words are automatically marked as known unless you add
              them as learning words. This helps you track your progress.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>
              Fast translations lookup and AI explanations
            </Card.Title>
            <Card.Text>
              You can look up any word instantly. Some words require more
              explanation through - that's what the AI is for. It will
              automatically explain the words based on the context.
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
    </div>
  );
}

export default Explanations;
