import React from "react";
import { Card, CardGroup, Image } from "react-bootstrap";
import ScreenshotVideos from "../screenshot-videos-1.png";

function Videos() {
  const imageContainerStyle = {
    position: "relative",
    display: "inline-block", // This makes the container fit the image size
  };

  return (
    <div>
      <h1
        className="text-center"
        style={{ marginBottom: "5%", marginTop: "10%" }}
      >
        Watch Videos On Interesting Topics
      </h1>
      <h4 className="text-center pb-4">
        Train your listening with hand-picked native speaker content
      </h4>

      <div className="d-flex justify-content-center mt-5 mb-4">
        <div style={imageContainerStyle}>
          <Image
            src={ScreenshotVideos}
            fluid
            style={{
              width: "100%",
              maxWidth: "900px",
              border: "1px solid lightgray",
              borderRadius: "10px",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "150px", // Adjust the height to control the fade area
              backgroundImage:
                "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
            }}
          />
        </div>
      </div>

      <CardGroup style={{ marginBottom: "0%" }}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>Train your listening</Card.Title>
            <Card.Text>
              Both reading and listening are essential for acquiring a language.
              Videos help make audio more comprehensible. If you can't
              understand the audio, you can read the subtitles or get the
              meaning from the video itself.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>Import YouTube captions for further study</Card.Title>
            <Card.Text>
              Import captions from the Video feed or straight from YouTube
              (using the browser extension). This way you can study the words
              and phrases you don't know.
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
    </div>
  );
}

export default Videos;
