import React from "react";
import { Card, CardGroup } from "react-bootstrap";

// import AppMetaDataContext from "../context/AppMetaDataContext";

function TheSecretSauce() {
  // const metaData = React.useContext(AppMetaDataContext);

  return (
    <div>
      <h1
        className="text-center"
        style={{ marginBottom: "7%", marginTop: "10%" }}
      >
        The Secret Sauce - Comprehensible Input
      </h1>

      <CardGroup style={{ marginBottom: "0%" }}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>
              Not too easy, not too hard - just right
            </Card.Title>
            <Card.Text>
              The content you study from should be just a little bit above your comfort zone. But not too much. If it's too easy, you won't learn anything. If it's too hard, you'll get frustrated and give up.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>
              Language acquisition, not language learning
            </Card.Title>
            <Card.Text>
              We acquire languages subconsciously not through conscious learning. You need lots of input (reading and listening) for that. You need to 10x the amount of text and audio you see and hear, not the amount of exercises you do.
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>

    </div>
  );
}

export default TheSecretSauce;
