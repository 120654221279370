import React, { useState, useEffect } from "react";
import { Button, Form, Alert, Spinner, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Magic, BoxArrowUpRight } from 'react-bootstrap-icons'; // Import the icons

function LibraryImportSong() {
  const context = React.useContext(AuthContext);

  const { id } = useParams(); // Get the 'id' parameter from the URL
  const navigate = useNavigate();

  const [contentYoutubeURL, setContentYoutubeURL] = useState("");
  const [contentTitle, setContentTitle] = useState("");
  const [contentBody, setContentBody] = useState("");
  const [privacySetting, setPrivacySetting] = useState("public");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [importSuccessMessage, setImportSuccessMessage] = useState("");
  const isEditMode = id !== undefined; // Determine if the component is in edit mode or import mode
  const [contentArtist, setContentArtist] = useState(""); // New state for artist
  const [contentBodyLength, setContentBodyLength] = useState(0); // New state for lyrics character count

  useEffect(() => {
    if (isEditMode) {
      // Fetch the song data and populate the form fields
      setIsLoading(true);
      console.log("Trying to fetch song with id: ", id);
      axios
        .get(`/api/songs/${id}`)
        .then((response) => {
          const {
            title = "",
            lyrics = "",
            artist = "",
            privacy = "private",
            youtube_video_id = "", // Assuming the response includes this field
          } = response.data.song; // Adjusted to match the expected response structure
          setContentTitle(title);
          setContentBody(lyrics);
          setContentArtist(artist);
          setContentBodyLength(lyrics?.length); // Update character count after fetching
          setPrivacySetting(privacy);
          setContentYoutubeURL(`https://www.youtube.com/watch?v=${youtube_video_id}`); // Reconstruct the YouTube URL
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching song:", error);
          setError("Error occurred while fetching song: " + error.message);
          setContentTitle(""); // Set default values
          setContentBody("");
          setContentArtist("");
          setPrivacySetting("private");
          setIsLoading(false);
        });
    }
  }, [id, isEditMode]);

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleTitleChange = (event) => {
    setContentTitle(event.target.value);
  };

  const handleYoutubeURLChange = (event) => {
    setContentYoutubeURL(event.target.value);
  };

  const handleBodyChange = (event) => {
    setContentBody(event.target.value);
    setContentBodyLength(event.target.value.length); // Update character count
  };

  const handlePrivacyChange = (event) => {
    setPrivacySetting(event.target.value);
  };

  const handleArtistChange = (event) => {
    setContentArtist(event.target.value);
  };

  const validateInput = () => {
    if (!contentArtist || contentArtist.length < 2 || contentArtist.length > 60) {
      setError("The artist name must be between 2 and 60 characters.");
      return false;
    }

    if (!contentTitle || contentTitle.length < 4 || contentTitle.length > 60) {
      setError("The title must be between 4 and 60 characters.");
      return false;
    }

    if (!contentBody || contentBody.length < 10 || contentBody.length > 10000) {
      setError("The lyrics must be between 10 and 10,000 characters.");
      return false;
    }

    if (!contentYoutubeURL) {
      setError("YouTube URL is required.");
      return false;
    }

    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})(\?si=[a-zA-Z0-9_-]+)?/;
    if (!youtubeRegex.test(contentYoutubeURL)) {
      setError("Invalid YouTube URL. Please enter a valid YouTube URL.");
      return false;
    }

    return true;
  };

  const submitArticle = () => {
    if (!validateInput()) {
      return;
    }

    setError("");
    setImportSuccessMessage("");
    setIsLoading(true);

    const songData = {
      youtube_url: contentYoutubeURL,
      title: contentTitle,
      lyrics: contentBody,
      artist: contentArtist,
      privacy: privacySetting,
      source: "webapp",
      language: context.getSelectedLanguagePair().language_learning.code,
    };

    const request = isEditMode
      ? axios.put(`/api/user/songs/${id}`, songData)
      : axios.post("/api/user/songs", songData);

    request
      .then((response) => {
        console.log(
          isEditMode ? "Update successful:" : "Import successful:",
          response.data
        );
        setImportSuccessMessage(
          `Song ${isEditMode ? "updated" : "saved"} successfully.`
        );
        setIsLoading(false);
        if (!isEditMode) {
          navigate("/library?tab=songs"); // Redirect to /library after successful import
        }
      })
      .catch((error) => {
        console.error(
          isEditMode ? "Error updating song:" : "Error importing song:",
          error
        );
        setError(
          `Error occurred while ${
            isEditMode ? "updating" : "importing"
          } song: ` + error.message
        );
        setIsLoading(false);
      });
  };

  const deleteSong = () => {
    const confirmDeletion = window.confirm(
      "Are you sure you want to delete this song?"
    );
    if (!confirmDeletion) {
      return; // Stop the function if the user cancels the action
    }

    setIsLoading(true); // Set loading state
    setError(""); // Clear any previous error messages

    axios
      .delete(`/api/user/songs/${id}`)
      .then((response) => {
        console.log("Song deleted successfully:", response.data);
        setIsLoading(false);
        navigate("/library"); // Redirect to the library after deletion
      })
      .catch((error) => {
        console.error("Error deleting song:", error);
        setError("Error occurred while deleting the song: " + error.message);
        setIsLoading(false);
      });
  };

  const extractYoutubeVideoId = (url) => {
    const match = url.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    return match ? match[1] : null;
  };

  const updateWithAI = () => {
    const videoId = extractYoutubeVideoId(contentYoutubeURL);
    if (!videoId) {
      setError("Invalid YouTube URL. Please enter a valid YouTube URL.");
      return;
    }

    const confirmOverwrite = window.confirm(
      "This will overwrite the artist, title, and lyrics fields. Do you want to continue?"
    );
    if (!confirmOverwrite) {
      return;
    }

    setIsLoading(true);
    axios
      .post("/api/songs/get-lyrics", { videoId })
      .then((response) => {
        const { artist, title, lyrics } = response.data;
        setContentArtist(artist);
        setContentTitle(title);
        setContentBody(lyrics);
        setContentBodyLength(lyrics?.length); // Update character count after fetching
        setError("");
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching lyrics:", error);
        if (error.response && error.response.status === 404) {
          setError("Tried to fetch lyrics but couldn't: " + error.response.data.message);
        } else {
          setError("Tried to fetch lyrics but couldn't: " + error.message);
        }
        setIsLoading(false);
      });
  };

  return (
    <Container
      className="d-flex pt-5 flex-column justify-content-top"
      style={{ minHeight: "70vh" }}
    >
      <h2>
        {isEditMode ? "Edit" : "Add"} Song{" "}
        {isLoading && (
          <Spinner animation="border" role="status" className="ms-3">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </h2>
      <Form>
        <Form.Group controlId="formContentImport">
          <Form.Label className="mt-3">YouTube link to the song</Form.Label>
          <Row className="align-items-center">
            <Col xs={12} md={5}>
              <Form.Control
                value={contentYoutubeURL}
                onChange={handleYoutubeURLChange}
                placeholder={`For example: https://www.youtube.com/watch?v=6jWsIpAbo-8`}
                disabled={isEditMode}
              />
            </Col>
            <Col xs={12} md="auto" className="mt-2 mt-md-0">
              <Button
                variant="dark"
                onClick={updateWithAI}
                disabled={isLoading}
                className="d-flex align-items-center"
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                ) : (
                  <Magic className="me-2" />
                )}{" "}
                Get song details & lyrics
              </Button>
            </Col>
          </Row>
          <Form.Label className="mt-3">Artist</Form.Label>
          <Row>
            <Col xs={12} md={8}>
              <Form.Control
                value={contentArtist}
                onChange={handleArtistChange}
                placeholder={`For example: "Madonna"`}
              />
            </Col>
          </Row>
          <Form.Label className="mt-3">Song Title</Form.Label>
          <Row>
            <Col xs={12} md={8}>
              <Form.Control
                value={contentTitle}
                onChange={handleTitleChange}
                placeholder={`For example: "Hung Up"`}
              />
            </Col>
          </Row>
          <Form.Label className="mt-3 d-flex align-items-center">
            Song lyrics
            <Button
              variant="outline-primary"
              onClick={() =>
                window.open(
                  `https://www.google.com/search?q=${encodeURIComponent(
                    contentArtist + " " + contentTitle + " lyrics"
                  )}`,
                  "_blank"
                )
              }
              className="ms-2 d-flex align-items-center"
              size="sm"
            >
              Search in Google
              <BoxArrowUpRight className="ms-2" />
            </Button>
          </Form.Label>
          <Row>
            <Col xs={12} md={8}>
              <Form.Control
                as="textarea"
                rows={10}
                value={contentBody}
                onChange={handleBodyChange}
                placeholder="Enter song lyrics here"
              />
              <p className="mt-2 text-muted small">
                Characters:{" "}
                <span
                  style={{
                    color: contentBodyLength > 10000 ? "red" : "inherit",
                  }}
                >
                  {contentBodyLength.toLocaleString()}
                </span>{" "}
                / 10,000
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              {" "}
              {/* Adjust the column size as needed */}
              <Form.Select
                value={privacySetting}
                onChange={handlePrivacyChange}
                className="mt-3"
              >
                <option value="private">Private (only you can see it)</option>
                <option value="public">
                  Public (other users can see it too)
                </option>
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
        {error && (
          <Alert variant="danger" className="mt-3 mb-0">
            {error}
          </Alert>
        )}
        {importSuccessMessage && (
          <Alert variant="success" className="mt-3 mb-0">
            {importSuccessMessage}
          </Alert>
        )}
        <Button
          variant="primary"
          onClick={submitArticle}
          disabled={
            isLoading ||
            !contentArtist ||
            !contentTitle ||
            !contentBody ||
            !contentYoutubeURL
          }
          className="my-3"
        >
          {isLoading
            ? isEditMode
              ? "Updating..."
              : "Importing..."
            : isEditMode
            ? "Update"
            : "Import"}
        </Button>
        {isEditMode && (
          <Link to={"/music/" + id}>
            <Button variant="dark" className="my-3 ms-3">
              Listen & study
            </Button>
          </Link>
        )}
        {isEditMode && (
          <Button variant="danger" className="my-3 ms-3" onClick={deleteSong}>
            Delete
          </Button>
        )}
      </Form>
    </Container>
  );
}

export default LibraryImportSong;
