import React, { memo, useContext } from "react";
import AuthContext from "../context/AuthContext";

// TODO: the logic for adding spaces between words and adding spaces between paragraphs and title should be here

const Word = memo(({ word, index, onClick }) => {
  // console.log("WORD: ", word)
  const { known_words } = useContext(AuthContext);
  // find if the word is inside known_words (it's an array of objects where the word is the key)
  const isKnown = known_words.find((kw) => kw.word === word.word);
  
  let wordClass;

  if (isKnown) {
    if (!isKnown.translation) {
      wordClass = "skipped";
    } else {
      wordClass = "known";
    }
  } else {
    wordClass = "unknown";
  }

  return (
    <>
      <span id={`word-${index}`} className={wordClass} onClick={onClick}>
        {word.word}
      </span>{" "}
    </>
  );
});

export default Word;
