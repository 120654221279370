import React from "react";
import { Card, CardGroup } from "react-bootstrap";

import AppMetaDataContext from "../../context/AppMetaDataContext";
import context from "react-bootstrap/esm/AccordionContext";

function BuiltForComprehensibleInput() {
  const metaData = React.useContext(AppMetaDataContext);

  return (
    <div>
      <h1
        className="text-center"
        style={{ marginBottom: "7%", marginTop: "10%" }}
      >
        Built For Comprehensible Input
      </h1>

      <CardGroup style={{ marginBottom: "0%" }}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>
              Filter content based on your vocabulary level
            </Card.Title>
            <Card.Text>
              The app saves the words you already know automatically. It then
              highlights the words you don't know. You can filter the content
              based on the percentage of the words you know.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>Filter content based on your interests</Card.Title>
            <Card.Text>
              It's easier to read the content about something you already know a
              little about. {metaData.appName} lets you filter the content based
              on your interests.
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>

      <CardGroup style={{ marginBottom: "13%" }}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>See the words in context</Card.Title>
            <Card.Text>
              The key to picking up new words is seeing them in context.
              Although you can also use flashcards for further practice, the
              main way of acquiring any language works through reading and
              listening actual sentence not separate words or fictional
              situations.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>See the translations in context</Card.Title>
            <Card.Text>
              Our AI-powered translation engine translates the words in context.
              You can see the translations of the words in the context of the
              sentence. This means translations are more accurate and the AI
              will further explain the words you don't know.
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
    </div>
  );
}

export default BuiltForComprehensibleInput;
