import React from "react";
import { Card, CardGroup, Image } from "react-bootstrap";
import ScreenshotNews from "../screenshot-news-1.png";

function Customize() {
  const imageContainerStyle = {
    position: "relative",
    display: "inline-block", // This makes the container fit the image size
  };

  return (
    <div>
      <h1
        className="text-center"
        style={{ marginBottom: "5%", marginTop: "10%" }}
      >
        Learn From Up-To-Date News That Interests You
      </h1>
      <h4 className="text-center pb-4">Nothing is made up for 'real life scenarios' - this is the real deal</h4>

      <div className="d-flex justify-content-center mt-5 mb-4">
        <div style={imageContainerStyle}>
          <Image
            src={ScreenshotNews}
            fluid
            style={{
              width: "100%",
              maxWidth: "900px",
              border: "1px solid lightgray",
              borderRadius: "10px",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "300px", // Adjust the height to control the fade area
              backgroundImage:
                "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
            }}
          />
        </div>
      </div>

      {/* <CardGroup style={{ marginBottom: "0%" }}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>Only actual native speaker content</Card.Title>
            <Card.Text>
              Lingo Champion lets you read and listen to actual news articles -
              the same that native speakers read. You'll learn the vocabulary
              that you actually need.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>Nothing is made up - everything is real</Card.Title>
            <Card.Text>
              All the texts are real news articles about the current events.
              Nothing is made up.
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup> */}

      <CardGroup style={{ marginBottom: "0%" }}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>Choose news articles based on your interest</Card.Title>
            <Card.Text>
              Don't just read any news article. Choose the ones that you are
              truly interested in. You'll learn the vocabulary that you actually
              need.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>
              Choose the difficulty level that's right for you
            </Card.Title>
            <Card.Text>
              Some text is too easy and some is too hard. Choose the difficulty
              level that's right for you - based on the vocabulary you already
              know. You can also simplify articles even further with AI.
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
    </div>
  );
}

export default Customize;
