import React, { useState, useEffect } from "react";
import {
  ProgressBar,
  Alert,
  Badge,
  ListGroup,
  Image,
  Button,
  Dropdown,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Wallet2, Headphones, ChatRightQuote } from "react-bootstrap-icons";
import levels from "./data/Levels";
import { Link } from "react-router-dom";
import chalice from "../chalice-150x150.png";
import PlaylistModal from "./PlaylistModal";
import { GeneratingAudioModal } from "./AudioModals";


// BUG: if I add a skipped word as a learning word, it shows 0 new learning words added
// BUG: if I delete a word and then add a word, it shows 0 new words added


const LevelCurrent = ({
  knownWordsArray,
  knownWordsAtTheStart,
  currentArticle,
  audioUrl,
  generateAudioIsProcessing,
  setGenerateAudioIsProcessing,
  setShowPlaylistSelectionModal,
  showPlaylistSelectionModal,
  setAudioUrl,
  articleId,
}) => {
  // Initialize the word count with knownWordsAtTheStart.length
  const [wordCount, setWordCount] = useState(knownWordsAtTheStart.length);

  let navigate = useNavigate();

  // find out how many words in the kownWordsAtTheStart haven't got a translation
  const wordsWithTranslation = knownWordsAtTheStart.filter(
    (word) => word.translation
  );
  // find out how many words in the knownWordsArray haven't got a translation
  const newWordsWithTranslation = knownWordsArray.filter(
    (word) => word.translation
  );

  useEffect(() => {
    // Set a timeout to update the word count after 1 second
    const timer = setTimeout(() => {
      setWordCount(knownWordsArray.length);
    }, 1000);

    // Clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [knownWordsArray.length]);

  // useEffect for getting the articleId from location
  // useEffect(() => {
  //   const articleId = window.location.pathname.split("/").pop();
  //   console.log("Article ID: ", articleId);
  //   setArticleId(articleId);
  // }, []);

  const getCurrentLevelIndex = () => {
    return levels.findIndex((level) => wordCount <= level.range);
  };

  const getLevelProgress = (index) => {
    if (index === getCurrentLevelIndex()) {
      const currentLevel = levels[index];
      const previousLevelRange = index > 0 ? levels[index - 1].range : 0;
      const rangeBetweenLevels = currentLevel.range - previousLevelRange;
      const progressWithinLevel = wordCount - previousLevelRange;
      return (progressWithinLevel / rangeBetweenLevels) * 100;
    }
    return 0;
  };

  const getCurrentLevelTitle = () => {
    const currentIndex = getCurrentLevelIndex();
    return currentIndex >= 0 ? levels[currentIndex].title : "Unknown";
  };

  return (
    <>
    <Alert variant="light" className="my-3 mx-2">
      <Image src={chalice} fluid style={{ height: "100px" }} />
      <h4 className="mb-4">You finished the article!</h4>
      <strong>Your next level: {getCurrentLevelTitle()}</strong>
      <Link to="/statistics" style={{ textDecoration: "none" }}>
        <ProgressBar
          now={getLevelProgress(getCurrentLevelIndex())}
          variant={
            getLevelProgress(getCurrentLevelIndex()) === 100
              ? "success"
              : getLevelProgress(getCurrentLevelIndex()) > 66
              ? "warning"
              : "primary"
          }
          label={`${Math.floor(getLevelProgress(getCurrentLevelIndex()))}%`}
          className="mt-2 mb-2"
        />
      </Link>
      <ListGroup as="ol" className="mt-5 mb-3">
        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start"
        >
          <div className="ms-1 me-auto">
            <div className="fw-bold">New learning words</div>
          </div>
          <Badge bg="primary" pill className="ms-4">
            {newWordsWithTranslation.length - wordsWithTranslation.length}
          </Badge>
        </ListGroup.Item>
        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start"
        >
          <div className="ms-1 me-auto">
            <div className="fw-bold">New skipped words</div>
          </div>
          <Badge bg="primary" pill className="ms-4">
            {knownWordsArray.length -
              knownWordsAtTheStart.length -
              (newWordsWithTranslation.length - wordsWithTranslation.length)}
          </Badge>
        </ListGroup.Item>
      </ListGroup>

      {/* <Button
        variant="secondary"
        className="me-2 mt-3 mb-3"
        // disabled={learningWords.length === 0}
      >
        <Wallet2 style={{ transform: "rotate(-90deg)", marginBottom: "2px" }} />{" "}
        Listen to the article
      </Button> */}
      <div className="b-0 pt-3">
        <Row className="d-flex align-items-center justify-content-center">
          <Col xs={12} md="auto" className="my-1">
            <Dropdown className="">
              <Dropdown.Toggle
                variant="primary"
                // size="sm"
                // className="d-flex justify-content-center align-items-center"
                style={{ fontWeight: 400 }}
              >
                    {generateAudioIsProcessing
                      ? <Spinner animation="grow" size="sm" className="me-1 mb-0" />
                      : <Headphones className="me-1 mb-1" />
                    } Listen
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  // href="#/action-1"
                  onClick={() => setGenerateAudioIsProcessing(true)}
                  disabled={generateAudioIsProcessing}
                >
                  Generate audio for the content
                </Dropdown.Item>
                <Dropdown.Item
                  // href="#/action-2"
                  onClick={() => {
                    if (audioUrl) {
                      navigate("/player/" + currentArticle._id);
                    }
                  }}
                  disabled={!audioUrl}
                >
                  Listen to the article
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                  setShowPlaylistSelectionModal(true);

                  }}
                  disabled={!audioUrl}
                  >
                    Add to playlist
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs={12} md="auto" className="my-1">
            <Button
              variant="dark"
              onClick={() => navigate(`/chat?articleId=${currentArticle._id}`)}
              style={{ fontWeight: 400 }}
            >
              <ChatRightQuote className="me-1 mb-1" /> Discuss with AI
            </Button>
          </Col>
          <Col xs={12} md="auto" className="my-1">
            <Dropdown className="">
              <Dropdown.Toggle
                variant="primary"
                // size="sm"
                // className="d-flex justify-content-center align-items-center"
                style={{ fontWeight: 400 }}
              >
                <Wallet2
                  style={{ transform: "rotate(-90deg)", marginBottom: "2px" }}
                />{" "}
                Review with flashcards
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  // href="#/action-1"
                  onClick={() => {
                    navigate(
                      "/vocabulary/review/" + articleId + "?type=general"
                    );
                  }}
                >
                  General flashcards (lowest strength first)
                </Dropdown.Item>
                <Dropdown.Item
                  // href="#/action-2"
                  onClick={() => {
                    navigate("/vocabulary/review/" + articleId + "?type=srs");
                  }}
                >
                  SRS flashcards (earliest due date first)
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>
      <br />
      </Alert>

      <GeneratingAudioModal
        articleId={currentArticle?._id}
        generateAudioIsProcessing={generateAudioIsProcessing}
        setGenerateAudioIsProcessing={setGenerateAudioIsProcessing}
        setAudioUrl={setAudioUrl}
        setShowPlaylistSelectionModal={setShowPlaylistSelectionModal}
      />

      <PlaylistModal show={showPlaylistSelectionModal} article={currentArticle} setShow={setShowPlaylistSelectionModal} />

      </>
  );
};

export default LevelCurrent;
