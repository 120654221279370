import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, ListGroup, Form } from 'react-bootstrap';

const PlaylistsModal = ({ show, setShow, article }) => {
    const [playlists, setPlaylists] = useState([]);
    const [newPlaylistName, setNewPlaylistName] = useState('');
    const [selectedPlaylists, setSelectedPlaylists] = useState(new Set());

    useEffect(() => {
        const fetchPlaylists = async () => {
            try {
                const response = await axios.get(`/api/user/playlists`);
                console.log("Response from fetching playlists: ", response.data);
                const filteredPlaylists = response.data.filter(playlist => playlist.language === article.language);
                console.log("Filtered playlists: ", filteredPlaylists);
                setPlaylists(filteredPlaylists || []);
            } catch (error) {
                console.error('Error fetching playlists:', error);
            }
        };

        if (show) {
            fetchPlaylists();
        }
    }, [show]);

    const handleCheckboxChange = (playlistId) => {
        setSelectedPlaylists(prevState => {
            const newSet = new Set(prevState);
            if (newSet.has(playlistId)) {
                newSet.delete(playlistId);
            } else {
                newSet.add(playlistId);
            }
            return newSet;
        });
    };

    const handlePlaylistSelect = async () => {
        try {
            const response = await axios.post(`/api/user/playlists/add`, {
                articleId: article._id,
                playlistIds: Array.from(selectedPlaylists),
                language: article.language
            });
            console.log('Response from adding article to playlist:', response.data);
            setShow(false);
            // clear selected playlists
            setSelectedPlaylists(new Set());
        } catch (error) {
            console.error('Error adding article to playlist:', error);
        }
    };

    const handleCreateNewPlaylist = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/user/playlist', {
                name: newPlaylistName,
                language: article.language
            });
            console.log('Response from creating new playlist:', response.data);
            setPlaylists([...playlists, response.data.playlist]);
            setNewPlaylistName('');
        } catch (error) {
            console.error('Error creating new playlist:', error);
        }
    };

    const handleClose = () => {
        setShow(false);
        // clear selected playlists when closing the modal
        setSelectedPlaylists(new Set());
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Select playlist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {playlists.map(playlist => (
                        <ListGroup.Item key={playlist._id}>
                            <Form.Check
                                type="checkbox"
                                label={playlist.name}
                                checked={selectedPlaylists.has(playlist._id)}
                                onChange={() => handleCheckboxChange(playlist._id)}
                            />
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                <Form onSubmit={handleCreateNewPlaylist} className="mt-3">
                    <Form.Group controlId="formNewPlaylist">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Form.Control
                                type="text"
                                placeholder="Enter playlist name"
                                value={newPlaylistName}
                                onChange={(e) => setNewPlaylistName(e.target.value)}
                            />
                            <Button variant="primary" type="submit" className="ms-2">
                                Create
                            </Button>
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handlePlaylistSelect}>
                    Save to playlist(s)
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PlaylistsModal;
