import React from "react";
import {
  Container
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";

import socialImage from "../lingo-champion-social-sharing.png";

import LearnDuolingoVocabularyMainCTA from "../Components/LearnDuolingoVocabularyMainCTA";
import HowItWorks from "../Components/HowItWorks";
import Benefits from "../Components/Benefits";
import BackedByScience from "../Components/BackedByScience";
import Customize from "../Components/Customize";
import ComprehensibleInputSections from "./comprehensible-input/ComprehensibleInputSections";
import FounderQuote from "../Components/FounderQuote";
import DownloadExtension from "../Components/DownloadExtension";
import BuiltForComprehensibleInput from "./comprehensible-input/BuiltForComprehensibleInput";


function Home() {
  const metaData = React.useContext(AppMetaDataContext);
  return (
    <>
      <Helmet>
        <title>
          Comprehensible Input in Language Learning - {metaData.appName}
        </title>
        <meta
          name="description"
          key="description"
          content="Comprehensible input is the most important factor in language acquisition. Learn where the term came from and how to get optimal comprehensible input in your target language."
        />
        <meta property="og:image" key="og:image" content={socialImage} />
        <meta
          property="og:title"
          key="og:title"
          content={metaData.metaTitleSocial}
        />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDescSocial}
        />
      </Helmet>

      <Container style={{ padding: "1% 5%" }}>
        <h1 className="mt-4">
          What Is Comprehensible Input And How To Use It In Language Learning
        </h1>
        <ComprehensibleInputSections />
      </Container>

      <Container style={{ padding: "3% 3%" }}>
        <LearnDuolingoVocabularyMainCTA />
        {/* <HowItWorks /> */}
        <BuiltForComprehensibleInput />
        <Benefits />
        <BackedByScience />
        <FounderQuote />
        <DownloadExtension />
      </Container>
    </>
  );
}

export default Home;
