import React, { useState, useContext, useEffect } from "react";
import { Accordion, ProgressBar, Form, Button, Modal } from "react-bootstrap";
import AppMetaDataContext from "../context/AppMetaDataContext";
import AuthContext from "../context/AuthContext";
import { Youtube } from "react-bootstrap-icons";
import axios from "axios";

const ChecklistProgress = ({ studyArticles }) => {
  const metaData = useContext(AppMetaDataContext);
  const context = useContext(AuthContext);
  // State for checklist items
  const [checklist, setChecklist] = useState([
    { id: 1, text: "Add your interests (to chat with AI)", checked: true },
    { id: 2, text: "Read a news article", checked: false },
    { id: 3, text: "Save words to your vocabulary", checked: false },
    { id: 4, text: "Practice words with flashcards", checked: false },
    { id: 5, text: "Import or generate your own content", checked: false },
  ]);

    const [importedArticles, setImportedArticles] = useState([]);
    const [importedArticlesHaveBeenChecked, setImportedArticlesHaveBeenChecked] = useState(false);

  // State for modal visibility
  const [showModal, setShowModal] = useState(false);

  const [isAccordionExpanded, setIsAccordionExpanded] = useState("0");

  useEffect(() => {
    if (context.known_words?.length > 0) {
      setIsAccordionExpanded(null);
    }
  }, [context.known_words]);

  // Function to toggle modal visibility
  const toggleModal = () => setShowModal(!showModal);

  useEffect(() => {
    const updateChecklist = () => {
      setChecklist((prevChecklist) =>
        prevChecklist.map((item) => {
          if (item.id === 2 && studyArticles?.length > 0) {
            return { ...item, checked: true };
          }
          if (item.id === 3 && context.known_words?.length > 0) {
            return { ...item, checked: true };
          }
          if (
            item.id === 4 &&
            context.known_words?.some(
              (word) => word?.strength > 0 && word?.translation
            )
          ) {
            return { ...item, checked: true };
          }
          if (item.id === 5 && importedArticles.length > 0) {
            return { ...item, checked: true };
          }
          return item;
        })
      );
    };

    updateChecklist();
  }, [studyArticles, context.known_words, importedArticles]);

  useEffect(() => {
    // Check for imported articles
    const fetchImportedArticles = async () => {
      try {
        const response = await axios.get(`/api/user/articles/imported`, {
          params: {
            language: context.getSelectedLanguagePair().language_learning.code,
          },
        });

        setImportedArticles(response.data.articles);
        setImportedArticlesHaveBeenChecked(true);
      } catch (error) {
        console.error("Error checking imported articles:", error);
        setImportedArticlesHaveBeenChecked(true);
      }
    };

    fetchImportedArticles();
  }, [metaData]);

  // Function to toggle checklist item
  const toggleChecklistItem = (id) => {
    setChecklist((prevChecklist) =>
      prevChecklist.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  // Calculate progress based on checklist
  const calculateProgress = () => {
    const completedItems = checklist.filter((item) => item.checked).length;
    return (completedItems / checklist.length) * 100;
  };

  const progress = calculateProgress();

  if (progress === 100 || !importedArticlesHaveBeenChecked) {
    return null; // Hide the component if all items are checked
  }

  return (
    <>
      <Accordion className="mt-4 mb-5" defaultActiveKey={isAccordionExpanded}>
        <h3 className="pb-2">Getting Started</h3>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div style={{ flex: 1 }}>
              <Button
                variant="success"
                className="d-flex align-items-center"
                onClick={toggleModal}
              >
                <Youtube className="me-2" />
                <span className="d-none d-sm-inline me-1">Watch</span> Tutorial
              </Button>
            </div>
            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <ProgressBar
                now={progress}
                variant={
                  progress === 100
                    ? "success"
                    : progress > 66
                    ? "warning"
                    : "primary"
                }
                label={`${Math.floor(progress)}%`}
                style={{ width: "50%" }}
              />
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <Form>
              {checklist.map((item) => (
                <Form.Check
                  key={item.id}
                  type="checkbox"
                  label={item.text}
                  checked={item.checked}
                  className="mb-2"
                  style={{ fontSize: "1.2rem" }}
                />
              ))}
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Fullscreen Modal for video */}
      <Modal show={showModal} onHide={toggleModal} fullscreen centered>
        <Modal.Header closeButton>
          <Modal.Title>Watch Tutorial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <div style={{ flexGrow: 1, maxWidth: "80%", aspectRatio: "16/9", position: "relative" }}>
              <iframe
                loading="lazy"
                className="sl-demo"
                src="https://app.storylane.io/demo/jjsvacjakhj7?embed=inline"
                name="sl-embed"
                allow="fullscreen"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  border: "1px solid rgba(63,95,172,0.35)",
                  boxShadow: "0px 0px 18px rgba(26, 19, 72, 0.15)",
                  borderRadius: "10px",
                  boxSizing: "border-box",
                }}
              ></iframe>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChecklistProgress;
