import React from "react";
import { Image } from "react-bootstrap";
import AppsComparisonImage from "../../language-learning-apps-comparison-comprehensible-input.png"

export default function ComprehensibleInputSections({ language }) {

  const imageContainerStyle = {
    position: "relative",
    display: "inline-block", // This makes the container fit the image size
  };
  
  return (
    <>
      <div className="mt-4">
        <h2>What is Comprehensible Input?</h2>
        <p>
          The idea of {language ? <a href="/comprehensible-input">comprehensible input</a> : "comprehensible input"} is a transformative approach to
          language learning, introduced by linguist Stephen Krashen. This
          concept suggests that the optimal progress in language learning occurs
          when individuals engage with language material that is just above
          their existing level of competence, yet still within their grasp of
          understanding.
        </p>
        <p>
          Essentially, it's about delivering learning content that pushes
          learners slightly out of their comfort zone, thereby fostering
          advancement without causing discouragement.
        </p>
      </div>

      <div className="mt-4">
        <h2>The Benefits of Comprehensible Input</h2>
        <p>Here's what you can expect:</p>
        <ul>
          <li>
            <strong>Reduced anxiety</strong>: when you understand most of the
            language you are exposed to, you feel more confident and less
            anxious.
          </li>
          <li>
            <strong>Increased efficiency</strong>: the focus is on understanding
            rather than memorization, resulting in faster progress.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h2>
          Improve Your {language && language} Fluency With Comprehensible Input
        </h2>
        <p>
          Comprehensible input is a powerful tool. However, it can be
          challenging to find the right materials
          {language ? ` in ${language}` : ""}. That's where Lingo Champion comes
          in. Lingo Champion serves you news articles in{" "}
          {language ? `${language}` : "your target language"}, with translations and definitions
          of the words you don't know. This way, you can get optimal comprehensible
          input and acquire the language quicker.
        </p>
      </div>
      <div className="mt-4 mb-5">
        <h2>Which apps provide comprehensible input?</h2>
        <p>This is <a href="https://comprehensibleinput.io/language-learning-apps-comparison" target="_blank">a comparison of language learning apps</a> based on whether they provide comprehensible input or not and how much free choice to they give to the user.</p>
        <div className="d-flex justify-content-center mt-5 mb-4">
        <div style={imageContainerStyle}>
          <Image
            src={AppsComparisonImage}
            fluid
            style={{
              width: "100%",
              maxWidth: "900px",
              border: "1px solid lightgray",
              borderRadius: "10px",
              padding: "2em 4em",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "150px", // Adjust the height to control the fade area
              backgroundImage:
                "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
            }}
          />
        </div>
      </div>
      </div>
    </>
  );
}
