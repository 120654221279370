import React from "react";
import { Card, CardGroup, Image } from "react-bootstrap";
import FluencyGraph from "../fluency-in-the-first-language-comprehensible-input.png"

// import AppMetaDataContext from "../context/AppMetaDataContext";

function WeDontTrainLinguists() {
  // const metaData = React.useContext(AppMetaDataContext);

    const imageContainerStyle = {
    position: "relative",
    display: "inline-block", // This makes the container fit the image size
  };

  return (
    <div>
      <h1
        className="text-center"
        style={{ marginBottom: "7%", marginTop: "10%" }}
      >
        The Secret Sauce - 10x More Input
      </h1>

     <div className="d-flex justify-content-center mt-5 mb-4">
        <div style={imageContainerStyle}>
          <Image
            src={FluencyGraph}
            fluid
            style={{
              width: "100%",
              maxWidth: "900px",
              border: "1px solid lightgray",
              borderRadius: "10px",
              padding: "2em 4em",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "150px", // Adjust the height to control the fade area
              backgroundImage:
                "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
            }}
          />
        </div>
      </div>

      <Card
        className="border-0 mb-5"
        style={{ marginLeft: "10%", marginRight: "10%" }}
      >
        <blockquote className="blockquote">
          {/* <em> */}
          <strong>
            You’re not bad at learning languages, but you might be bad at studying
            linguistics.
          </strong>{" "}
          Most language classes and apps are run by linguists. This is why they
          force you to memorize grammar rules, study a fixed curriculum, do
          writing and speaking exercises, etc. It won’t help you much in becoming fluent.
          Writing and speaking come after comprehension, not before. <br />
          <br />
          <strong
            style={{
              background:
                "linear-gradient(90deg, rgba(255, 255, 0, 0.0) 0%, rgba(255, 255, 0, 0.75) 1%, rgba(255, 255, 0, 0.0) 100%)",
            }}
          >
            You need 10x more input (reading and listening) and from native speaker content.
          </strong>{" "}
          Without any stress from grammar lessons, tests, or meaningless exercises.{" "}
          <strong>Trust your brain</strong> to pick up the language. Exactly as
          you did as a child.
          {/* </em> */}
        </blockquote>
      </Card>

      {/* <CardGroup style={{ marginBottom: "0%" }}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>No forced grammar lessons</Card.Title>
            <Card.Text>
              Did you learn grammar rules before you learned your mother tongue?
              No. You learned by listening and reading. Most methods push for
              explicit grammar learning because they've been created by
              linguists who study grammar. We don't do that. We help you learn
              like a child learns.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>No anxiety-inducing tests</Card.Title>
            <Card.Text>
              Perfectionism is the killer of all joy. But no one is perfect even
              in their mother tongue. We know more vocabulary in certain topics
              and less in some others. We have our own way of speaking. We
              backtrack. We glide over. We make mistakes. It's fine. You'll get
              better over time, don't worry.
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup> */}
    </div>
  );
}

export default WeDontTrainLinguists;
