import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Wallet2 } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import LevelCurrent from "../Components/LevelCurrent";

export default function ReaderFinishedPage({
  knownWordsAtTheStart,
  knownWordsArray,
  currentArticle,
  articleId,
  audioUrl,
  generateAudioIsProcessing,
  setGenerateAudioIsProcessing,
  setAudioUrl,
  showPlaylistSelectionModal,
  setShowPlaylistSelectionModal,
}) {
  return (
    <Container className="d-flex" style={{ minHeight: "80vh" }}>
      <Row className="m-auto w-100 mb-2">
        <Col className="d-flex flex-column align-items-center justify-content-center">
          <div className="text-center">
            <LevelCurrent
              knownWordsArray={knownWordsArray}
              knownWordsAtTheStart={knownWordsAtTheStart}
              currentArticle={currentArticle}
              audioUrl={audioUrl}
              generateAudioIsProcessing={generateAudioIsProcessing}
              setGenerateAudioIsProcessing={setGenerateAudioIsProcessing}
              articleId={articleId}
              setAudioUrl={setAudioUrl}
              showPlaylistSelectionModal={showPlaylistSelectionModal}
              setShowPlaylistSelectionModal={setShowPlaylistSelectionModal}
            />

            <Link to="/library">
              <Button variant="secondary" className="mx-2 mt-3">
                &larr; Back to Library
              </Button>
            </Link>
            <Button
              variant="warning"
              onClick={() => {
                window.open("https://tally.so/r/wbW5X1", "_blank");
              }}
              className="mx-2 mt-3"
            >
              Send feedback
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
