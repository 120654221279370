import React, { useContext, useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import AuthContext from "../context/AuthContext";
import LearnedWordsChart from "./LearnedWordsChart";
import LearnedWordsChartCumulative from "./LearnedWordsChartCumulative";
import ReadWordsChart from "./ReadWordsChart";
import ReadWordsChartCumulative from "./ReadWordsChartCumulative";
import LevelsFullProgress from "./LevelsFullProgress";
import StreakWeek from "./StreakWeek";

const Charts = ({
  learningWords = [],
  skippedWords = [],
  knownWordsArray = [],
}) => {
  const context = useContext(AuthContext);
  console.log("CONTEXT IN CampaignList.js: ", context);

  const [learningWordsByDay, setLearningWordsByDay] = useState([]);
  const [learningWordsByDayCumulative, setLearningWordsByDayCumulative] =
    useState([]);
  const [readWordsByDayCumulative, setReadWordsByDayCumulative] = useState([]);

  // Process learningWords to get count by date where translation is defined
  const processDataForChart = (words) => {
    console.log("== WORDS: ", words);
    const filteredWords = words.filter(
      (word) => typeof word.translation === "string"
    );
    const countsByDate = filteredWords.reduce((acc, { date_added }) => {
      const date = new Date(date_added).toISOString().split("T")[0];
      if (!acc[date]) {
        acc[date] = 0;
      }
      acc[date]++;
      return acc;
    }, {});

    return Object.entries(countsByDate).map(([date, count]) => ({
      date,
      count,
    }));
  };

  // useEffect for learning words
  useEffect(() => {
    if (learningWords.length === 0) return;
    setLearningWordsByDay(processDataForChart(learningWords));
    setLearningWordsByDayCumulative(
      processDataForChartCumulative(learningWords)
    );
  }, [learningWords]);

  const processDataForChartCumulative = (words) => {
    const filteredWords = words.filter(
      (word) => typeof word.translation === "string"
    );
    const countsByDate = filteredWords.reduce((acc, { date_added }) => {
      const date = new Date(date_added).toISOString().split("T")[0];
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    const sortedDates = Object.keys(countsByDate).sort();
    const cumulativeData = sortedDates.reduce((acc, date) => {
      const lastCount = acc.length > 0 ? acc[acc.length - 1].count : 0;
      acc.push({
        date,
        count: lastCount + countsByDate[date],
      });
      return acc;
    }, []);

    return cumulativeData;
  };

  const convertToObjectArray = (data) => {
    return Object.entries(data).map(([date, count]) => ({
      date,
      count,
    }));
  };

  useEffect(() => {
    if (context.statistics?.wordsReadByDay) {
      // console.log("CONTEXT STATISTICS: ", context.statistics);
      // console.log(
      //   "CONTEXT STATISTICS CUMULATIVE: ",
      //   makeDataCumulative(context.statistics)
      // );
      setReadWordsByDayCumulative(
        makeDataCumulative(context.statistics.wordsReadByDay)
      );
    }
  }, [context.statistics]);

  const makeDataCumulative = (data) => {
    let cumulativeCount = 0;
    return data.map((entry) => {
      cumulativeCount += entry.count;
      return {
        date: entry.date,
        count: cumulativeCount,
      };
    });
  };

  // const readWordsChartArray = convertToObjectArray(context.statistics);

  return (
    <div className="mb-5" style={{ maxWidth: "700px", margin: "0 auto" }}>
      <StreakWeek />

      <LevelsFullProgress currentWordCount={knownWordsArray.length} />

      <ListGroup as="ol" className="my-5">
        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start"
        >
          <div className="ms-2 me-auto">
            <div className="fw-bold">Learning words</div>
            Words that you have added to your vocabulary with a translation.
          </div>
          <Badge bg="primary" pill>
            {learningWords.length}
          </Badge>
        </ListGroup.Item>
        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start"
        >
          <div className="ms-2 me-auto">
            <div className="fw-bold">Skipped words (known words)</div>
            Words you've skipped over by going to the next page of the article
            without adding them to your vocabulary with a translation. We assume
            you knew these words already.
          </div>
          <Badge bg="primary" pill>
            {skippedWords.length}
          </Badge>
        </ListGroup.Item>
        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start"
        >
          <div className="ms-2 me-auto">
            <div className="fw-bold">Total unique words in your vocabulary</div>
            Skipped and learning words combined.
          </div>
          <Badge bg="primary" pill>
            {knownWordsArray.length}
          </Badge>
        </ListGroup.Item>
      </ListGroup>

      <Card className="mt-3 my-3">
        <Card.Body>
          <Card.Title>New Learning Words Added (by Day)</Card.Title>
          <LearnedWordsChart data={learningWordsByDay} />
        </Card.Body>
      </Card>

      <Card className="mt-3 my-3">
        <Card.Body>
          <Card.Title>Learning Words by Day (Cumulative)</Card.Title>
          <LearnedWordsChartCumulative data={learningWordsByDayCumulative} />
        </Card.Body>
      </Card>

      <Card className="mt-3 my-3">
        <Card.Body>
          <Card.Title>Words Read by Day</Card.Title>
          <ReadWordsChart data={context.statistics?.wordsReadByDay || []} />
        </Card.Body>
      </Card>

      <Card className="mt-3 my-3">
        <Card.Body>
          <Card.Title>Words Read by Day (Cumulative)</Card.Title>
          <ReadWordsChartCumulative data={readWordsByDayCumulative} />
        </Card.Body>
      </Card>
    </div>
  );
};

export default Charts;
