import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";

export default function DuolingoUninstallMessage() {
  const [show, setShow] = useState(true);

  useEffect(() => {
    // check if utm_medium = duolingo_ninja_uninstall
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramsObj = {};
    for (const [key, value] of urlParams.entries()) {
      paramsObj[key] = value;
    }
    if (paramsObj["utm_medium"] === "duolingo_ninja_uninstall") {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  if (show) {
    return (
      <>
        {/* center in the middle horizontally */}
        <div className="d-flex justify-content-center mb-5">
          <Alert
            variant="danger"
            onClose={() => setShow(false)}
            dismissible
            style={{ maxWidth: "700px" }}
          >
            <Alert.Heading>
              Import your Duolingo vocabulary and start reading native speaker content
            </Alert.Heading>
            <p>
              <strong>Lingo Champion</strong> is now open for everyone. Check it out below!
            </p>
          </Alert>
        </div>
      </>
    );
  }
}
