import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../../context/AppMetaDataContext";

import socialImage from "../../lingo-champion-social-sharing.png";

import ComprehensibleInputSections from "./ComprehensibleInputSections";
import ComprehensibleInputImports from "./ComprehensibleInputImports";

function Home() {
  const metaData = React.useContext(AppMetaDataContext);
  const language = "Spanish";
  const helmetMetaContent = `${language} comprehensible input for language acquisition. Plus a short overview of what comprehensible input is.`;
  return (
    <>
      <Helmet>
        <title>
          {language} Comprehensible Input for Language Learning -{" "}
          {metaData.appName}
        </title>
        <meta
          name="description"
          key="description"
          content={helmetMetaContent}
        />
        <meta property="og:image" key="og:image" content={socialImage} />
        <meta
          property="og:title"
          key="og:title"
          content={metaData.metaTitleSocial}
        />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDescSocial}
        />
      </Helmet>

      <Container style={{ padding: "1% 5%" }}>
        <h1 className="mt-4">
          {language} Comprehensible Input for Language Learning
        </h1>
        <ComprehensibleInputSections language={language} />
      </Container>

      <Container style={{ padding: "3% 3%" }}>
        <ComprehensibleInputImports language={language} />
      </Container>
    </>
  );
}

export default Home;
