import React from "react";
import { Card, CardGroup } from "react-bootstrap";

function Customize() {

  return (
    <div>
      <h1
        className="text-center"
        style={{ marginBottom: "7%", marginTop: "10%" }}
      >
        27 Different Types Of Exercises? No thanks
      </h1>

      <CardGroup style={{ marginBottom: "0%" }}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>Less isn't more - it's better</Card.Title>
            <Card.Text>
              Don't mimic real life. Act in real life. Don't do exercises. Consume interesting content instead.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>
              Reading has everything you need built in
            </Card.Title>
            <Card.Text>
              Frequency lists? No need. The most frequent words appear more often anyway. You do have the option to use flashcards if you want to but it's not necessary.
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>

    </div>
  );
}

export default Customize;
