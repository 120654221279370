import React from "react";
import { Card, CardGroup } from "react-bootstrap";

// import AppMetaDataContext from "../context/AppMetaDataContext";

function WhyDuolingoIsNotEnough() {
  // const metaData = React.useContext(AppMetaDataContext);

  return (
    <div>
      <h1
        className="text-center"
        style={{ marginBottom: "7%", marginTop: "10%" }}
      >
        Why Apps Like Duolingo Won't Make You Fluent
      </h1>

      <CardGroup style={{ marginBottom: "0%" }}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>You don't see enough text</Card.Title>
            <Card.Text>
              Duolingo is great at bite-sized lessons. But you don't see enough
              text to start seeing patterns and start thinking in the language.
              You need a lot of{" "}
              <a href="/comprehensible-input">comprehensible input</a> for that.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>The text is too simple and meaningless</Card.Title>
            <Card.Text>
              Duolingo does have a lot of sentences that are theoritically
              useful. But they are practically meaningless because they don't
              have any immediate purpose. You are not trying to visit a museum
              in Spain while you are using the phone on your couch in England.
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>

      <CardGroup style={{ marginBottom: "0%" }}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>
              Trying to memorize grammar explicitly isn't that useful
            </Card.Title>
            <Card.Text>
              No child ever learned their mother tongue by studying grammar and
              then consciously applying it. In fact, it's very difficult to
              think about grammar while speaking. You do need some but you'll
              pick up the rest subconsciously by seeing lots of text.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>
              Duolingo doesn't teach you based on your interests
            </Card.Title>
            <Card.Text>
              It forces you into a learning track. But people have different
              interests that have different vocabulary. You can't standardize
              interests. You can somewhat alleviate that with tools like{" "}
              <a href="https://duolingoninja.com" target="_blank">
                Duolingo Ninja
              </a>{" "}
              but it's not enough.
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
    </div>
  );
}

export default WhyDuolingoIsNotEnough;
