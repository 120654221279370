import * as React from "react";
import AuthContext from "../context/AuthContext";

function StripePricingPage() {
  const context = React.useContext(AuthContext);

  // make sure test environment has test keys, and production env has live keys
  let publicKey;
  let pricingTableId;

  publicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  pricingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;

  // Paste the stripe-pricing-table snippet in your React component

  return (
    <stripe-pricing-table
      pricing-table-id={pricingTableId}
      publishable-key={publicKey}
      customer-email={context.email}
      client-reference-id={context.user_id}
    ></stripe-pricing-table>
  );
}

export default StripePricingPage;
