import React, { useEffect, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function BookDetailsModal({
  showModal,
  handleClose,
  collectionId,
}) {
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(false);

  // TODO: no need to fetch every time, only if the collectionId changed
  useEffect(() => {
    if (showModal) {
      fetchCollectionDetails(collectionId);
    }
  }, [showModal, collectionId]);

  const fetchCollectionDetails = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`/api/collections/${id}`);
      const data = await response.json();
      setBook(data);
    } catch (error) {
      console.error("Error fetching collection details:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{book?.title || "Loading..."}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div>
            <Spinner animation="grow" size="sm" className="me-2" />
            Loading the details...
          </div>
        ) : book ? (
          <>
            <p>
              <strong>Author:</strong> {book.author}
            </p>
            <p>
              <strong>Published:</strong>{" "}
              {book?.date_published?.slice(0, 4) || "Unknown"}
            </p>
            <p>
              <strong>Chapters:</strong> {book.articles?.length || 0}
            </p>
            <ol>
              {book.articles &&
                book.articles.map((chapter, idx) => (
                  <li key={idx}>
                    <Link
                      to={`/reader/${chapter?.article_id}?collectionId=${book._id}`}
                    >
                      {chapter?.title || `Chapter ${idx + 1}`}
                    </Link>
                  </li>
                ))}
            </ol>
          </>
        ) : (
          <p>Error loading collection details.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
