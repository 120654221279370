import React, { useState } from "react";
import TextPanel from "./TextPanel";
import TranslationPanel from "./TranslationPanel";
import { ReaderContext } from "../context/ReaderContext";

import { Container, Row, Col, Button, ProgressBar } from "react-bootstrap";

const Reader2 = () => {
  const [selectedWord, setSelectedWord] = useState(null);

  const [showDebuggingInfo, setShowDebuggingInfo] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(null);
  const [offsetHeight, setOffsetHeight] = useState(null);
  const [viewportWidth, setViewportWidth] = useState(null);
  const [endingWordIndex, setEndingWordIndex] = useState(null);
  const [currentArticlePage, setCurrentArticlePage] = useState(0);
  const [articlePages, setArticlePages] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  

  function closeReader() {
    console.log("closeReader");
  }

  function getPreviousPage() {
    console.log("getPreviousPage");
  }

  function getNextPage() {
    console.log("getNextPage");
  }

  function handleTouchStart(e) {
    console.log("handleTouchStart");
  }

  function handleTouchMove(e) {
    console.log("handleTouchMove");
  }

  function handleTouchStartTranslationsPanel(e) {
    console.log("handleTouchStartTranslationsPanel");
  }

  function handleTouchMoveTranslationsPanel(e) {
    console.log("handleTouchMoveTranslationsPanel");
  }

  return (
    // <ReaderContext>
      <Container>
        <Row style={{ minHeight: "100svh" }}>
          <Col
            xs={1}
            style={{
              border: showDebuggingInfo ? "1px solid blue" : "none",
              padding: "3vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column", // Step 1
            }}
            id="leftMenu"
          >
            <Button variant="light" onClick={closeReader} size="sm">
              &#10006;
            </Button>
            <Button
              variant="light"
              onClick={getPreviousPage}
              size="lg"
              className="arrowButton"
            >
              &larr;
            </Button>
            <Button
              variant="light"
              // onClick={() => setShowDebuggingInfo(!showDebuggingInfo)}
              size="sm"
              style={{
                color: "rgba(127, 127, 127, 0.1)",
                backgroundColor: "white",
              }}
            >
              &#128027;
            </Button>
          </Col>
          <Col
            style={{
              border: showDebuggingInfo ? "1px solid black" : "none",
              padding: "40px",
            }}
            id="textContent"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            {/* make showing debugging info conditional on the state variable */}
            {showDebuggingInfo && (
              <div id="debuggingInfo">
                <div className="debuggingInfo">
                  Viewport Height: {viewportHeight} pixels
                </div>
                <div className="debuggingInfo">
                  Offset Height: {offsetHeight} pixels
                </div>
                <div className="debuggingInfo">
                  Viewport Width: {viewportWidth} pixels
                </div>
                <div className="debuggingInfo">
                  Ending word index: {endingWordIndex}
                </div>
                <div className="debuggingInfo" style={{ marginBottom: "1em" }}>
                  Current article page: {currentArticlePage + 1} /{" "}
                  {articlePages.length}
                </div>
              </div>
            )}

            <div id="progressBar" className="mb-5 mt-2">
              <ProgressBar
                now={currentArticlePage}
                min={0}
                max={articlePages.length - 1}
                variant={
                  currentArticlePage === articlePages.length - 1 && "success"
                }
                style={{ height: "5px" }}
              />
            </div>

            <TextPanel setSelectedWord={setSelectedWord} />
          </Col>
          <Col
            xs={1}
            style={{
              border: showDebuggingInfo ? "1px solid blue" : "none",
              padding: "3vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="rightMenu"
          >
            <Button
              variant="light"
              onClick={getNextPage}
              size="lg"
              className="arrowButton"
            >
              &rarr;
            </Button>
          </Col>
          <Col
            xs={12}
            md={3}
            lg={3}
            style={{
              border: "2px solid lightgray",
              padding: "20px",
              borderRadius: "8px",
              marginTop: "2%",
              marginBottom: "2%",
            }}
            id="translations"
            tabIndex="0"
            className={isFullScreen ? "full-screen" : ""}
            onTouchStart={handleTouchStartTranslationsPanel}
            onTouchMove={handleTouchMoveTranslationsPanel}
          >
            <TranslationPanel selectedWord={selectedWord} />
          </Col>
        </Row>
      </Container>
    // </ReaderContext>
  );
};

export default Reader2;
