import React, { useState } from "react";
import { Card, Col, Button, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import PlaceholderImg from "../chalice-150x150.png";
import BookDetailsModal from "./BookDetailsModal";

export default function BookCard({ book, index }) {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  // Function to calculate read badges and percentages (not displayed for now)
  const calculateReadBadges = () => {
    // Placeholder function logic
    return {
      readPercentage: 0, // Example value
      unreadPercentage: 100, // Example value
    };
  };

  // Function to determine badge color based on read percentage
  const getBadgeColor = (percentage) => {
    if (percentage > 75) return "success";
    if (percentage > 50) return "warning";
    return "danger";
  };

  // Call the function to ensure it's retained
  const { readPercentage, unreadPercentage } = calculateReadBadges();

  return (
    <Col key={index} xs={12} className="mb-4">
      <Card>
        <Card.Body>
          <Row className="flex-column flex-md-row">
            <Col xs={12} md={2}>
              <div
                style={{
                  width: "100%",
                  paddingBottom: "56.25%",
                  position: "relative",
                }}
                className="mb-3 mb-md-0"
              >
                <Link
                  to={`/reader/${book?.articles[0]?.article_id}?collectionId=${book._id}`}
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <Card.Img
                    variant="top"
                    src={book.cover_image || PlaceholderImg}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: book.cover_image ? "cover" : "scale-down",
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = PlaceholderImg;
                      e.target.style.objectFit = "scale-down";
                    }}
                  />
                </Link>
              </div>
            </Col>
            <Col xs={12} md={10}>
              <Card.Title>
                <Link
                  to={`/reader/${book?.articles[0]?.article_id}?collectionId=${book._id}`}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {book.title}
                </Link>
              </Card.Title>

              <Card.Subtitle className="mb-2 text-muted">
                {book.author} |{" "}
                <span className="text-none">
                  {book.date_published ? book.date_published.slice(0, 4) : "Unknown"}
                </span>
              </Card.Subtitle>
              <Card.Text>
                {book?.articles?.length || 0} chapter{book?.articles?.length > 1 ? "s" : ""}
              </Card.Text>
              <Link to={`/reader/${book?.articles[0]?.article_id}?collectionId=${book._id}`}>
                <Button
                  variant="outline-dark"
                  className="btn-sm me-2 mb-2"
                >
                  Read & study
                </Button>
              </Link>
              <Button
                variant="outline-dark"
                className="btn-sm mb-2"
                onClick={handleShow}
              >
                Show details
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <BookDetailsModal
        showModal={showModal}
        handleClose={handleClose}
        collectionId={book._id}
      />
    </Col>
  );
}
