import React, { Component } from "react";

const AppMetaDataContext = React.createContext();

export class ReaderContext extends Component {
  state = {
    articleTitle: "",
    articleBody: "",
    articleTextObjectsArray: [],
    tokens:[],
    pages: [],
  };

  render() {
    const {
      articleTitle,
      articleBody,
      articleTextObjectsArray,
      tokens,
      pages,
    } = this.state;

    return (
      <ReaderContext.Provider
        value={{
          articleTitle,
          articleBody,
          articleTextObjectsArray,
          tokens,
          pages,
        }}
      >
        {this.props.children}
      </ReaderContext.Provider>
    );
  }
}

export default ReaderContext;
