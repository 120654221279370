import React from "react";
import { Card, CardGroup, Badge } from "react-bootstrap";

import AppMetaDataContext from "../context/AppMetaDataContext";

function BackedByScience() {
  const metaData = React.useContext(AppMetaDataContext);

  return (
    <div>
      <h1
        className="text-center"
        style={{ marginBottom: "7%", marginTop: "10%" }}
      >
        Backed By Science
      </h1>

      <CardGroup style={{ marginBottom: "0%" }}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>
              Following the best practices of language acquisition
            </Card.Title>
            <Card.Text>
              {metaData.appName} follows the latest research in language
              acquisition. It's based largely on the work of linguist Stephen
              Krashen{" "}
              <sup>
                <a href="https://www.sdkrashen.com/" target="_blank">
                  <Badge bg="secondary" pill>
                    1
                  </Badge>
                </a>
              </sup>
              . In short - we acquire languages unconsciously through reading
              and listening, not by conscious learning (studying grammar,
              writing, etc.). Ever seen a baby study grammar or write essays?
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>Input matters, not output</Card.Title>
            <Card.Text>
              We acquire languages mostly by input (reading, listening) not by
              output (speaking, writing){" "}
              <sup>
                <a
                  href="https://www.jstor.org/stable/326879?seq=1"
                  target="_blank"
                >
                  <Badge bg="secondary" pill>
                    2
                  </Badge>
                </a>
              </sup>
              . More output does not result in major improvements. It's the
              other way around. The more input you get, the better your output
              will be. This is why you progressed so slowly in school and with
              most language learning apps.
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>

      <CardGroup style={{ marginBottom: "13%" }}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>Made up texts are forgotten quickly</Card.Title>
            <Card.Text>
              Meaning matters. You remember better when the material has some
              meaning to you and you process it more deeply.{" "}
              <sup>
                <a
                  href="https://en.wikipedia.org/wiki/Levels_of_Processing_model#:~:text=Craik%20and%20Robert%20S.,a%20shallow%20to%20deep%20continuum."
                  target="_blank"
                >
                  <Badge bg="secondary" pill>
                    3
                  </Badge>
                </a>
              </sup>{" "}
              This is why the choice of topics by the learner is so important.{" "}
              <sup>
                <a
                  href="https://www.sciencedirect.com/science/article/abs/pii/0346251X78900271"
                  target="_blank"
                >
                  <Badge bg="secondary" pill>
                    4
                  </Badge>
                </a>
              </sup>{" "}
              And why the texts need to be authentic (the same ones native
              speakers read) not made up.
              <sup>
                <a
                  href="https://sdkrashen.com/content/articles/2014_lao_and_krashen._language_acquisition_without_speaking_and_study.pdf"
                  target="_blank"
                >
                  <Badge bg="secondary" pill>
                    5
                  </Badge>
                </a>
              </sup>{" "}
              <sup>
                <a
                  href="https://www.sciencedirect.com/science/article/pii/S1877042815034990"
                  target="_blank"
                >
                  <Badge bg="secondary" pill>
                    6
                  </Badge>
                </a>
              </sup>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>You can't be fluent in everything</Card.Title>
            <Card.Text>
              Almost everyone is fluent in everyday conversations in their
              native tongue. But what about using architecture terms vs medical
              terms? Or sports terms vs politics terms? You can't be fluent in
              everything. You need to make a choice and you need to be given
              optimal input - not too easy and not too hard.{" "}
              <sup>
                <a
                  href="https://www.sdkrashen.com/content/articles/university_of_isfahan_dec_2019.pdf"
                  target="_blank"
                >
                  <Badge bg="secondary" pill>
                    7
                  </Badge>
                </a>
              </sup>
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
    </div>
  );
}

export default BackedByScience;
