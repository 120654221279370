import React, { useState } from "react";
import { Button, Row, Col, Modal } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import YouTube from "react-youtube"; // Assuming you are using react-youtube for embedding YouTube videos
import { Youtube } from "react-bootstrap-icons";
import linkchimpImage from "../screenshot-front-combo-2.png";

// import AppMetaDataContext from "../context/AppMetaDataContext";

function LearnDuolingoVocabularyMainCTA({ language }) {
  // const metaData = React.useContext(AppMetaDataContext);

  // State to control the modal visibility
  const [showModal, setShowModal] = useState(false);

  // Function to toggle the modal
  const handleModalToggle = () => setShowModal(!showModal);

  return (
    <div>
      <Row style={{ margin: "0% 3%", marginBottom: "5%" }}>
        <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
          <h4 className="display-5 mb-5">
            Master {language ? language + " " : "languages "} through the
            content you love
          </h4>
          <h4>For expats & digital nomads - from “some Duolingo” to fluency</h4>
          <br />
          {/* New button to open the onboarding modal */}
          <Button
            size="lg"
            variant="primary"
            onClick={handleModalToggle}
            className="me-3 mt-3"
          >
            <Youtube className="me-2" /> See How It Works
          </Button>
          <LinkContainer to="/register" className="mt-3">
            <Button size="lg" variant="success">
              Try it out for free
            </Button>
          </LinkContainer>
          <br />
          <br />
          <p>No credit card required.</p>
        </Col>
        <Col
          xs={{ span: 12, order: 1 }}
          lg={{ span: 6, order: 2 }}
          style={{ paddingBottom: "5%" }}
        >
          <img src={linkchimpImage} alt="Logo" className="img-fluid" />
        </Col>
      </Row>
      <br />
      <br />
      <hr />

      {/* Modal for video */}
      <Modal
        show={showModal}
        onHide={handleModalToggle}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>How to use Lingo Champion</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <div className="video-responsive">
            <YouTube videoId={"5zql8gDq77I"} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LearnDuolingoVocabularyMainCTA;
