import React, { useState } from "react";
import { Button, Form, Alert, Collapse, Table } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import Container from "react-bootstrap/Container";
import axios from "axios";

function VocabularyImporter() {
  const [inputText, setInputText] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [importSuccessMessage, setImportSuccessMessage] = useState("");
  const [openAnki, setOpenAnki] = useState(false);
  const [openDuolingo, setOpenDuolingo] = useState(false);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const validateLine = (line) => {
    // const separator = line.includes(",") ? "," : "\t"; // find the seperator (whichever is found first)
    // const parts = line.split(separator);
    const parts = line.split("\t"); // Always split by tabs

    // Ensure there's at least a word and a translation
    if (parts.length < 2) {
      return false;
    }
    const [word, translation] = parts;
    if (word.length > 50 || translation.length > 50) {
      return false;
    }
    return true;
  };

  const parseStrength = (value) => {
    const number = parseInt(value, 10);
    return !isNaN(number) && number >= 0 && number <= 5 ? number : 0;
  };

  const importVocabulary = () => {
    setError("");
    const lines = inputText.split("\n");

    // Check if the first line is empty or contains column headers
    // loop throw the lines and delete empty lines
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].trim() === "") {
        lines.splice(i, 1);
        i--;
      }
    }

    // if the first line contains "word" or "translation" or "strength", remove it
    if (/word|translation|strength/i.test(lines[0].toLowerCase())) {
      lines.shift();
    }

    const vocabulary = [];

    for (const line of lines) {
      if (!validateLine(line)) {
        setError(
          "Error: Each line must have a word and a translation (each up to 100 characters), followed by optional additional values. Also, please make sure you didn't actually keep the column headers in the first line.\n\nThis the line that caused the error: \n\n" +
            line
        );

        console.log("Error: Invalid import line:", line);
        return;
      }

      const parts = line.split("\t"); // Always split by tabs
      const word = parts[0];
      const translation = parts[1];
      const strength =
        parts.length > 2 ? parseStrength(parts[parts.length - 1]) : 0;

      vocabulary.push({ word, translation, strength });
    }

    setIsLoading(true);

    console.log("Trying to import vocabulary:", vocabulary);

    axios
      .put("/api/user/vocabulary/import", { words: vocabulary })
      .then((response) => {
        console.log("Import successful:", response.data);
        setImportSuccessMessage(
          `Successfully imported ${response.data.added} words and updated ${response.data.updated} words.`
        );
        setInputText("");
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error importing vocabulary:", error);
        setError("Error occurred while importing vocabulary: " + error.message);
        setIsLoading(false);
      });
  };

  return (
    <Container
      className="d-flex pt-5 flex-column justify-content-top"
      style={{ minHeight: "70vh" }}
    >
      <h2>Import vocabulary</h2>
      <Form>
        <Form.Group controlId="formVocabularyImport">
          <Form.Label>
            <p>
              <strong>The columns have to be tab-separated.</strong> The safest
              way is to copy-paste from Excel or Google Sheets.
            </p>
            <h5>Sample spreadsheet</h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Word</th>
                  <th>Translation</th>
                  <th>
                    <span style={{ fontWeight: "400" }}>Word strength*</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>la casa</td>
                  <td>house</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>la stanza</td>
                  <td>room</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>el edificio</td>
                  <td>building</td>
                  <td>5</td>
                </tr>
              </tbody>
            </Table>
            <p>
              * Word strength is optional. It has to be a number between 0 (new
              word) and 5 (learned word). If no value is set, it will default to
              0.
            </p>
            <p>
              Things to keep in mind:
              <ul>
                <li>
                  The importer ignores all the columns between the translation
                  and the strength.
                </li>
                <li>
                  If the word already exists in your vocabulary, it will be
                  updated with the new values (of translation and strength).
                </li>
                <li>
                  Imported words will not get a due date for SRS flashcards.
                </li>
              </ul>
            </p>
            <div className="d-inline-block me-2">
              <Button
                onClick={() => setOpenAnki(!openAnki)}
                aria-controls="AnkiInstructions"
                aria-expanded={openAnki}
                variant="secondary"
                className="d-flex align-items-center"
              >
                <InfoCircle className="me-1" /> Anki instructions
              </Button>
              <Collapse in={openAnki}>
                <div id="AnkiInstructions" className="mt-2">
                  <h5>Anki instructions</h5>
                  <p>
                    Export your vocabulary from Anki as "Cards in plain text"
                    and do not include HTML and media references. Then paste the
                    contents of the file here. Make sure that the first lines
                    don't contain any metadata (column headers etc.). Also, make
                    sure that the foreign term is in the first column and the
                    translation in the second column. If they are reversed, you
                    should first copy-paste them to Excel or Google Sheets,
                    reverse the columns and then copy them to the importer.
                  </p>
                </div>
              </Collapse>

              <Button
                onClick={() => setOpenDuolingo(!openDuolingo)}
                aria-controls="DuolingoInstructions"
                aria-expanded={openDuolingo}
                variant="secondary"
                className="d-flex align-items-center mt-2"
              >
                <InfoCircle className="me-1" /> Duolingo instructions
              </Button>
              <Collapse in={openDuolingo}>
                <div id="DuolingoInstructions" className="mt-2">
                  <h5>Duolingo instructions</h5>
                  <p>
                    Use{" "}
                    <a href="https://duolingoninja.com" target="_blank">
                      Duolingo Ninja
                    </a>{" "}
                    browser extension to download your Duolingo vocabulary. You
                    can then copy-paste it here.
                  </p>
                </div>
              </Collapse>
            </div>
            <p></p>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={7}
            value={inputText}
            onChange={handleInputChange}
            placeholder="Paste tab-separated values here"
          />
        </Form.Group>
        {error && (
          <Alert variant="danger" className="mt-3 mb-0">
            {error}
          </Alert>
        )}
        {importSuccessMessage && (
          <Alert variant="success" className="mt-3 mb-0">
            {importSuccessMessage}
          </Alert>
        )}
        <Button
          variant="primary"
          onClick={importVocabulary}
          disabled={isLoading}
          className="my-3"
        >
          {isLoading ? "Importing..." : "Import"}
        </Button>
      </Form>
    </Container>
  );
}

export default VocabularyImporter;
