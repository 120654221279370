import React from "react";
import { Card, CardGroup, Badge, Image } from "react-bootstrap";

import flashcards from "../flashcards-big-update.png";

// import AppMetaDataContext from "../context/AppMetaDataContext";

function HowItWorks() {
  // const metaData = React.useContext(AppMetaDataContext);

  return (
    <>
      <div>
        <h1
          className="text-center"
          style={{ marginBottom: "7%", marginTop: "10%" }}
        >
          Features
        </h1>

        <CardGroup style={{ marginBottom: "0%" }}>
          <Card className="border-0">
            <Card.Body>
              <Card.Title style={{ textWrap: "balance" }}>
                See and download your Duolingo vocabulary
              </Card.Title>
              <Card.Text>
                Download the Duolingo vocabulary you've learned. This includes
                extra info like word strength and last practiced date.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="border-0">
            <Card.Body>
              <Card.Title style={{ textWrap: "balance" }}>
                Learn your Duolingo words by browsing the web
              </Card.Title>
              <Card.Text>
                Browse the web as normal and see your{" "}
                <a href="/duolingo-vocabulary-list">Duolingo vocabulary</a>{" "}
                translated on the web pages you visit. See the words (or full
                sentences) in real content and learn them in context.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="border-0">
            <Card.Body>
              <Card.Title style={{ textWrap: "balance" }}>
                Use flashcards to practice the vocabulary
              </Card.Title>
              <Card.Text>
                Practice the vocabulary that you might not see on web pages you
                visit. Flashcards work on the principle of spaced repetition -
                you'll see the ones you get wrong more often.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
        <h1
          className="text-center"
          style={{ marginBottom: "7%", marginTop: "10%" }}
        >
          How Learning By Browsing Works
        </h1>

        <CardGroup style={{ marginBottom: "0%" }}>
          <Card className="border-0">
            <Card.Body>
              <Card.Title style={{ textWrap: "balance" }}>
                1. Install the browser extension for free
              </Card.Title>
              <Card.Text>
                Go to the{" "}
                <a
                  href="https://chrome.google.com/webstore/detail/duolingo-champion/cdiecbgkdbkloiniekbfblbdbjgehpgb"
                  target="_blank"
                  rel="noreferrer"
                >
                  Chrome Web Store
                </a>{" "}
                to install the extension with one click.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="border-0">
            <Card.Body>
              <Card.Title style={{ textWrap: "balance" }}>
                2. Synchronize the words you've learned from Duolingo
              </Card.Title>
              <Card.Text>
                Choose your base language. Then synchronize the{" "}
                <a href="/duolingo-vocabulary-list">words</a> you've learned in
                Duolingo.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="border-0">
            <Card.Body>
              <Card.Title style={{ textWrap: "balance" }}>
                2. Browse the web as normal
              </Card.Title>
              <Card.Text>
                Translations are inserted between the original language text
                based on your Duolingo vocabulary. This way you can derive the
                meaning of a word or sentence from the context.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
        <h1
          className="text-center"
          style={{ marginBottom: "3%", marginTop: "10%" }}
        >
          Level Up With Flashcards{" "}
          <sup>
            <Badge bg="success" pill className="mt-2">
              New!
            </Badge>
          </sup>
        </h1>

        <div className="d-flex justify-content-center">
          {/* Ensure the parent container allows for full width */}
          <div style={{ maxWidth: "800px" }}>
            <Image
              src={flashcards}
              style={{ maxWidth: "100%", height: "auto" }} // Set max-width and auto height for responsiveness
              className="mb-3 mt-0 px-3"
              fluid
            />
          </div>
        </div>

        <CardGroup style={{ marginBottom: "0%" }}>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>Practice the vocabulary with flashcards</Card.Title>
              <Card.Text>
                Keep practicing the vocabulary that you might not see on web
                pages you visit. Flashcards work on the principle of spaced
                repetition - you'll see the ones you get wrong more often. You
                can filter flashcards by Duolingo skills (such as "Family" and
                "Travel").
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="border-0">
            <Card.Body>
              <Card.Title>
                See the words in AI-generated sample sentences
              </Card.Title>
              <Card.Text>
                Flashcards contain AI-generated sample sentences to help you
                understand the context.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
    </>
  );
}

export default HowItWorks;
