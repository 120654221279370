import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Alert,
  Button,
  ListGroup,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useSearchParams, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";

export default function Account() {
  const context = useContext(AuthContext);
  const metaData = useContext(AppMetaDataContext);
  const navigate = useNavigate();

  // check if a callback arrived from Stripe's checkout - not sure if needed
  // right now creates an infinite loop - doesn't stop checking
  // const [searchParams] = useSearchParams();
  // const subscribedToNewPlan = searchParams.get("subscription")
  // if (subscribedToNewPlan) context.getAuthInfo();

  const [languages, setLanguages] = useState([]); // State to store fetched languages
  const [speakLang, setSpeakLang] = useState("");
  const [learnLang, setLearnLang] = useState("");
  const [showAddCourseModal, setShowAddCourseModal] = useState(false);
  const [addCourseIsLoading, setAddCourseIsLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const stripeSuccess = searchParams.get("subscription");

  const [interests, setInterests] = useState(context.settings.interests || "");
  const [isUpdatingInterests, setIsUpdatingInterests] = useState(false); // New state for loading

  // Function to handle updating interests
  const handleUpdateInterests = () => {
    setIsUpdatingInterests(true); // Start loading
    context.updateInterests(interests);
    console.log("Interests updated:", interests);

    // Add a delay before stopping the loading spinner
    setTimeout(() => {
      setIsUpdatingInterests(false); // Stop loading after delay
    }, 500); // 500ms delay
  };

  // Fetch and sort languages when component mounts
  useEffect(() => {
    if (!showAddCourseModal) return;

    axios
      .get("/api/languages")
      .then((response) => {
        const sortedLanguages = response.data.languages.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setLanguages(sortedLanguages);
      })
      .catch((error) => {
        console.error("Error fetching languages:", error);
      });
  }, [showAddCourseModal]);

  // Fetch the list of time zones
  const [timeZones, setTimeZones] = useState([]);

  useEffect(() => {
    console.log("User's current timezone from context: ", context.timezone);
    // This approach relies on the Intl API to fetch the supported time zones
    // Note: Not all environments may support this method fully
    const zones = Intl.supportedValuesOf("timeZone");
    setTimeZones(zones.sort());
    // console.log("TimeZones: ", zones);
  }, []);

  const handleTimeZoneChange = (event) => {
    // Update the user's selected time zone in your context or state
    // context.setTimeZone(event.target.value);
    console.log("Timezone was changed: ", event.target.value);
    context.updateTimeZone(event.target.value);
  };

  const setNewsletterStatus = (e) => {
    console.log("Event: ", e.target.checked);
    const request = {
      value: e.target.checked,
    };
    axios
      .put("/api/user/update-newsletter-setting", request)
      .then((response) => {
        console.log("RESPONSE: ", response.data);
      });
  };

  const updateNotificationSetting = (settingName, e) => {
    console.log(`Updating ${settingName}: `, e.target.checked);
    const request = {
      settingName,
      value: e.target.checked,
    };
    axios
      .put("/api/user/update-notification-setting", request)
      .then((response) => {
        console.log("RESPONSE: ", response.data);
      })
      .catch((error) => {
        console.error("Error updating notification setting: ", error);
      });
  };

  function handleShowAddCourseModal() {
    console.log("Add course clicked");
    setShowAddCourseModal(true);
  }

  function handleAddCourse(e) {
    e.preventDefault();
    setAddCourseIsLoading(true);
    console.log("Trying to add a new course...");

    // make a request to backend
    const request = {
      course: {
        language_learning: learnLang,
        language_base: speakLang,
      },
    };
    axios
      .put("/api/user/courses/add", request)
      .then(() => {
        context.getAuthInfo(); // Refresh auth info after adding a course
        setShowAddCourseModal(false);
        setAddCourseIsLoading(false);
      })
      .catch((error) => {
        setAddCourseIsLoading(false);
        console.error("Error adding a course:", error);
      });
  }

  function handleDeleteCourse(e, courseId) {
    // disable the button that was clicked
    e.target.disabled = true;
    axios
      .delete(`/api/user/courses/${courseId}`)
      .then(() => {
        console.log(`Course with id ${courseId} deleted.`);
        context.getAuthInfo(); // Refresh auth info after deleting a course
      })
      .catch((error) => {
        e.target.disabled = false;
        console.error("Error deleting course:", error);
      });
  }

  const handleDeleteAccount = async () => {
    try {
      const response = await axios.delete("/api/user/delete");
      if (response.status === 200) {
        alert("Account deleted successfully.");
        navigate("/logout"); // Redirect to the logout route
      }
    } catch (error) {
      console.error("Error deleting account: ", error);
      alert("Failed to delete account. Please try again.");
    }
  };

  // const setNotificationsStatus = (e) => {
  //   console.log("Event: ", e.target.checked);
  //   const request = {
  //     value: e.target.checked,
  //   };
  //   axios
  //     .put("/api/user/update-notifications-setting", request)
  //     .then((response) => {
  //       console.log("RESPONSE: ", response.data);
  //     });
  // };

  // change buttons for updating plans based on current plan
  let planButtons;
  switch (context.plan) {
    case "Premium plan":
      planButtons = (
        <>
          {/* <Button
            href={process.env.REACT_APP_STRIPE_BILLING_PORTAL_URL}
            className="mb-2 mb-sm-0 me-sm-2"
          >
            Change plan
          </Button> */}
          <Button
            href={process.env.REACT_APP_STRIPE_BILLING_PORTAL_URL}
            className="mb-2 mb-sm-0 me-sm-2"
          >
            Billing portal
          </Button>
          <Button
            href={process.env.REACT_APP_STRIPE_BILLING_PORTAL_URL}
            className="mb-2 mb-sm-0 me-sm-2"
          >
            Change plan
          </Button>
        </>
      );
      break;
    default:
      planButtons = (
        <LinkContainer to="/pricing">
          <Button variant="success">Go premium</Button>
        </LinkContainer>
      );
      break;
  }

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <Helmet>
        <title>Account Settings - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <h1 className="display-4 mb-5">Account Settings</h1>

      <br />
      <br />

      <div className="w-100" style={{ maxWidth: "700px" }}>
        {stripeSuccess ? (
          <Alert variant="success" className="mb-5">
            <strong>Plan change successful.</strong> Please allow a few minutes
            for updating if you don't see your new plan show up here
            immediately.
          </Alert>
        ) : (
          <></>
        )}
        <h4>
          Your language courses{" "}
          <Button
            className="d-inline"
            size="sm"
            variant="success"
            onClick={handleShowAddCourseModal}
          >
            &#43; Add course
          </Button>
        </h4>
        <ListGroup>
          {context.language_pairs.map((course, index) => (
            <ListGroup.Item
              as="li"
              key={course._id}
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <strong>{course.language_learning.name}</strong> (based on{" "}
                {course.language_base.name})
              </div>
              <Button
                variant="danger"
                disabled={context.language_pairs.length === 1}
                onClick={(e) => handleDeleteCourse(e, course._id)}
              >
                Delete
              </Button>
            </ListGroup.Item>
          ))}
        </ListGroup>
        <br />
        <br />

        <Modal
          show={showAddCourseModal}
          onHide={() => setShowAddCourseModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Course</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group id="learn-lang" className="my-2">
              <Form.Label>I want to learn</Form.Label>
              <Form.Select
                onChange={(e) => setLearnLang(e.target.value)}
                value={learnLang}
              >
                <option value="" disabled>
                  Select language
                </option>
                {languages.map((lang) => (
                  <option key={lang._id} value={lang.code}>
                    {lang.name}
                  </option>
                ))}
                {/* Add more options as needed */}
              </Form.Select>
            </Form.Group>

            <Form.Group id="speak-lang" className="my-2">
              <Form.Label>I speak</Form.Label>
              <Form.Select
                onChange={(e) => setSpeakLang(e.target.value)}
                value={speakLang}
              >
                <option value="" disabled>
                  Select language
                </option>
                {languages.map((lang) => (
                  <option key={lang._id} value={lang.code}>
                    {lang.name}
                  </option>
                ))}
                {/* Add more options as needed */}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowAddCourseModal(false)}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={handleAddCourse}
              disabled={addCourseIsLoading}
            >
              {addCourseIsLoading && (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
              )}
              Add Course
            </Button>
          </Modal.Footer>
        </Modal>

        <h4>Personal</h4>
        <ListGroup as="ol">
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Your email</div>
              {context.email}
            </div>
          </ListGroup.Item>

          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <Form.Group controlId="timeZoneSelect">
              <div className="ms-2 me-auto mb-2">
                <div className="fw-bold">Your timezone</div>
              </div>
              <div>
                <Form.Select
                  aria-label="Select Time Zone"
                  onChange={handleTimeZoneChange}
                  value={context.timezone}
                  className="mb-2"
                >
                  {timeZones.map((zone) => (
                    <option key={zone} value={zone}>
                      {zone}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div
                className="text-muted ms-2 me-auto"
                style={{ display: "inline-block" }}
              >
                Your streaks and statistics are based on the selected time zone.
              </div>
            </Form.Group>
          </ListGroup.Item>

          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <Form.Group controlId="interestsTextarea" className="w-100">
              <div className="ms-2 me-auto mb-2">
                <div className="fw-bold">Your interests</div>
              </div>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="For example: The history of Roman Empire, Mexican food, programming in JavaScript."
                className="mb-2"
                value={interests}
                onChange={(e) => setInterests(e.target.value)}
              />
              <div
                className="text-muted ms-2 me-auto"
                style={{ display: "inline-block" }}
              >
                This will help the AI to tailor the conversation to your
                interests.
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  className="my-2"
                  onClick={handleUpdateInterests}
                  disabled={isUpdatingInterests} // Disable button while loading
                >
                  {isUpdatingInterests && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                  )}
                  Update
                </Button>
              </div>
            </Form.Group>
          </ListGroup.Item>
        </ListGroup>

        <h4 className="mt-5">Notifications</h4>
        <ListGroup as="ol">
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto fw-bold">
              Product updates newsletter
            </div>
            <div>
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  defaultChecked={context.newsletter}
                  onClick={setNewsletterStatus}
                />
              </Form>
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Daily streak reminder email</div>
              <div
                className="text-muted me-auto"
                style={{ display: "inline-block" }}
              >
                Sent only when you're about to miss a streak. Not sent when you
                already missed a streak.
              </div>
            </div>
            <div>
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  defaultChecked={context.notifications.daily_streak_email}
                  onClick={(e) =>
                    updateNotificationSetting("daily_streak_email", e)
                  }
                />
              </Form>
            </div>
          </ListGroup.Item>
        </ListGroup>

        <h4 className="mt-5">Plans & billing</h4>
        <ListGroup as="ol">
          <ListGroup.Item
            as="li"
            className="d-flex flex-column align-items-start align-items-sm-center flex-sm-row justify-content-between"
          >
            <div className="ms-2 me-auto mb-2">
              <div className="fw-bold">Current plan</div>
              {context.plan}
            </div>
            <div className="d-flex flex-column align-items-start align-items-sm-center flex-sm-row">
              <LinkContainer to="/pricing" className="mb-2 mb-sm-0 me-sm-2">
                <Button>See all plans</Button>
              </LinkContainer>
              {planButtons}
            </div>
          </ListGroup.Item>
        </ListGroup>

        <h4 className="mt-5">Security</h4>
        <ListGroup as="ol">
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Password</div>
            </div>
            <LinkContainer to="/account/change-password">
              <Button href="/account/change-password">Change password</Button>
            </LinkContainer>
          </ListGroup.Item>
          <br />
          <br />
          <h4>Danger zone</h4>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Delete my account</div>
              <div
                className="text-muted me-auto"
                style={{ display: "inline-block" }}
              >
                This action cannot be undone.
              </div>
            </div>
            <Button variant="danger" onClick={handleDeleteAccount}>
              Delete
            </Button>
          </ListGroup.Item>
        </ListGroup>
      </div>
      <br />
      <br />
    </Container>
  );
}
