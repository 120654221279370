import React, { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../context/AuthContext";
import Word from "./Word";
import { createWords, splitByURLs } from "../utils/ReaderUtils";
import ReactDOM from "react-dom";

import articles from "./Reader-example.json";

import { useLocation } from "react-router-dom";

// let tokens = [];

const TextPanel = ({ setSelectedWord }) => {
  const { known_words } = useContext(AuthContext);

  const [currentArticlePage, setCurrentArticlePage] = useState(0);
  const [articlePages, setArticlePages] = useState([]);
  const [viewportHeight, setViewportHeight] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(0);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [startingWordIndex, setStartingWordIndex] = useState(0);
  const [endingWordIndex, setEndingWordIndex] = useState(0);
  // const [tokens, setTokens] = useState([]);
  const [renderTokens, setRenderTokens] = useState([]);

  let tokens = [];

  const currentArticleRef = useRef(null);
  const textWordsNode = useRef(null);

  useEffect(() => {
    console.log("Starting to update viewport heights etc.");
    const updateViewportHeight = () => {
      // if (viewportHeight === 0) {
      // NOTE: would use window.innerHeight otherwise but it doesn't work on mobile properly when the address bar is visible
      setViewportHeight(window.visualViewport.height);
      // }
    };

    const updateOffsetHeight = () => {
      setOffsetHeight(textWordsNode.current.offsetHeight);
    };

    const updateViewportWidth = () => {
      // NOTE: would use window.innerHeight otherwise but it doesn't work on mobile properly when the address bar is visible
      setViewportWidth(window.visualViewport.width);
    };

    // Initial updates
    updateViewportHeight();
    updateOffsetHeight();
    updateViewportWidth();

    // Attach the event listeners for window resize
    window.addEventListener("resize", updateViewportHeight);
    window.addEventListener("resize", updateViewportWidth);
    window.addEventListener("resize", updateOffsetHeight);
    window.addEventListener("orientationchange", updateViewportHeight);

    // Remove the event listeners when the component unmounts
    return () => {
      window.removeEventListener("resize", updateViewportHeight);
      window.removeEventListener("resize", updateOffsetHeight);
      window.removeEventListener("resize", updateViewportWidth);
      window.removeEventListener("orientationchange", updateViewportHeight);
    };
  }, []);

  let location = useLocation();
  useEffect(() => {
    console.log("location changed: ", location.pathname);
    const articleId = location.pathname.split("/")[2];
    console.log("articleId: ", articleId);
    // find the article with the id
    currentArticleRef.current = articles.articles.results.find(
      (article) => article.uri === articleId
    );
    console.log("currentArticle: ", currentArticleRef.current);
  }, [location]);

  useEffect(() => {
    // split the text property of the articleData object into an array of words
    const articleBody = currentArticleRef.current.body;
    const articleTitle = currentArticleRef.current.title;
    // const words = articleBody.split(" ");
    // console.log("WORDS: ", words);

    // First split by URLs
    // necessary in order not to have an URL split into words
    const splitByURL = splitByURLs(articleBody);

    // Further tokenize each non-URL element
    const finalWords = splitByURL.flatMap((fragment) => {
      if (!fragment.match(/https?:\/\/[^\s]+/)) {
        return fragment
          .split(/(\s+|\p{P}+|\p{L}+(?:\p{M}*\p{N}*)*)/gu)
          .filter((token) => token !== "");
      } else {
        return [fragment];
      }
    });

    const finalArticleTitle = articleTitle
      .split(/(\s+|\p{P}+|\p{L}+(?:\p{M}*\p{N}*)*)/gu)
      .filter((token) => token !== "");
    // console.log("TOKENS: ", tokens);
    let titleTokens = createWords(finalArticleTitle, known_words, "title");
    let bodyTokens = createWords(finalWords, known_words, "body");
    tokens = titleTokens.concat(bodyTokens);
    console.log("TOKENS: ", tokens);
    calculatePages(tokens, 0);
  }, []); // Empty dependency array ensures it runs once when the component mounts

  // run useEffect whentextWordsNode.current.offsetHeight changes
  useEffect(() => {
    console.log("textWordsNode.current.offsetHeight changed");
    console.log("Current viewport height: ", viewportHeight);
    console.log(window.visualViewport.height);
    console.log("Current offset height: ", offsetHeight);
    console.log(textWordsNode.current.offsetHeight);
    // calculatePages(tokens, 0);
  }, [textWordsNode.current]);

  // TODO: lastLearnedWordIndex should be kept in the database/state/context
  function calculatePages(textArray, lastLearnedWordIndex) {
    console.log(
      "Starting to calculate pages with lastLearnedWordIndex: ",
      lastLearnedWordIndex,
      "and the word is: ",
      textArray[lastLearnedWordIndex]
    );
    setRenderTokens([tokens[0]]);
    setRenderTokens([tokens[1]]);
    setRenderTokens([tokens[2]]);
  }

  function handleWordClick() {
    console.log("handleWordClick");
  }

  return (
    <div ref={textWordsNode} className="text-panel" id="TextContentWords">
      {renderTokens.map((word, index) => (
        <Word index={index} word={word} onClick={() => setSelectedWord(word)} />
      ))}
    </div>
  );
};

// <div id="debuggingInfo">
//   <div className="debuggingInfo">
//     Viewport Height: {viewportHeight} pixels
//   </div>
//   <div className="debuggingInfo">
//     Offset Height: {offsetHeight} pixels
//   </div>
//   <div className="debuggingInfo">
//     Viewport Width: {viewportWidth} pixels
//   </div>
//   <div className="debuggingInfo">
//     Ending word index: {endingWordIndex}
//   </div>
//   <div className="debuggingInfo" style={{ marginBottom: "1em" }}>
//     Current article page: {currentArticlePage + 1} / {articlePages.length}
//   </div>
// </div>;

export default TextPanel;
