import React, { useState, useEffect } from "react";
import { Button, Form, Alert, Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import axios from "axios";

function LibraryImportBook() {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [importSuccessMessage, setImportSuccessMessage] = useState("");
  const [languages, setLanguages] = useState([]); // State for languages
  const [selectedLanguage, setSelectedLanguage] = useState(""); // State for selected language

  useEffect(() => {
    axios
      .get("/api/languages")
      .then((response) => {
        const sortedLanguages = response.data.languages.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setLanguages(sortedLanguages);
      })
      .catch((error) => {
        console.error("Error fetching languages:", error);
      });
  }, []); // Fetch languages on component mount

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const submitFile = () => {
    if (!file) {
      setError("Please select an EPUB file to upload.");
      return;
    }

    setError("");
    setImportSuccessMessage("");
    setIsLoading(true);

    const formData = new FormData();
    formData.append("epub", file);
    formData.append("language", selectedLanguage); // Include selected language

    axios
      .post("/api/books", formData)
      .then((response) => {
        console.log("File upload successful:", response.data);
        setImportSuccessMessage("File uploaded successfully.");
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        setError("Error occurred while uploading the file: " + error.message);
        setIsLoading(false);
      });
  };

  return (
    <Container style={{ minHeight: "70vh" }}>
      <h2>Upload EPUB File</h2>
      <Form>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formFileUpload">
              <Form.Label className="mt-3">Select EPUB file</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formLanguageSelect">
              <Form.Label>Select Language</Form.Label>
              <Form.Control
                as="select"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                <option value="">- Select a language -</option>
                {languages.map((language) => (
                  <option key={language.code} value={language.code}>
                    {language.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {error && (
          <Alert variant="danger" className="mt-3 mb-0">
            {error}
          </Alert>
        )}
        {importSuccessMessage && (
          <Alert variant="success" className="mt-3 mb-0">
            {importSuccessMessage}
          </Alert>
        )}
        <Row>
          <Col md={6}>
            <Button
              variant="primary"
              onClick={submitFile}
              disabled={isLoading}
              className="my-3"
            >
              {isLoading ? "Uploading..." : "Upload"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default LibraryImportBook;
