import React, { Component } from "react";

const AppMetaDataContext = React.createContext();

export class AppMetaDataProvider extends Component {
  state = {
    appName: "Lingo Champion",
    metaTitle:
      "Lingo Champion - master languages through the content you love",
    metaDesc:
      "Read, watch, and listen to actual content from news sites and videos. Get AI to explain words to you. Filter and simplify content that matches your level. Chat with AI.",
    metaTitleSocial:
      "Lingo Champion - master languages through the content you love",
    metaDescSocial:
      "Read, watch, and listen to actual content from news sites and videos. Get AI to explain words to you. Filter and simplify content that matches your level. Chat with AI.",
    domain: "lingochampion.com",
  };

  render() {
    const {
      appName,
      metaTitle,
      metaDesc,
      metaTitleSocial,
      metaDescSocial,
      domain,
    } = this.state;

    return (
      <AppMetaDataContext.Provider
        value={{
          appName,
          metaTitle,
          metaDesc,
          metaTitleSocial,
          metaDescSocial,
          domain,
        }}
      >
        {this.props.children}
      </AppMetaDataContext.Provider>
    );
  }
}

export default AppMetaDataContext;
