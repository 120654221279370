const levels = [
  {
    title: "Newbie",
    range: 500,
    explainer:
      "A very basic ability to communicate, using simple phrases and everyday expressions for basic needs and introductions, often supplemented by gestures and visual aids. Comprehension and sentence formation are limited to the most straightforward and familiar language.",
  },
  {
    title: "Beginner",
    range: 1000,
    explainer:
      "Can understand and use basic phrases for practical needs and recognize some words in a variety of everyday texts. Cannot understand native speakers usually.",
  },
  {
    title: "Elementary",
    range: 2000,
    explainer:
      "Can understand one or two topics but with big limitations. Listening to native speakers is still very hard and it’s common to lose track. Speaking and writing are not possible in a meaningful way yet.",
  },
  {
    title: "Intermediate",
    range: 4000,
    explainer:
      "Can understand a few topics quite well. Can focus more on the meaning of the sentence than individual words. Struggles with grammar for some word forms, conjugations etc. Listening has improved but doesn’t come too easy yet.",
  },
  {
    title: "Upper Intermediate",
    range: 8000,
    explainer:
      "Can comprehend most everyday topics but with reservations. Listening to native speakers in the standard dialect is becoming possible but not without effort. No need to look up words usually in familiar topics. Speaking and writing are still painful and often require conscious recalling.",
  },
  {
    title: "Advanced",
    range: 16000,
    explainer:
      "Can understand the main ideas of complex texts on both concrete and abstract topics. Can understand native speakers when they speak the standard dialect. Speaking and writing are becoming quite easy and subconscious.",
  },
  {
    title: "Proficient",
    range: 32000,
    explainer:
      "Can express themselves spontaneously, fluently, and precisely, differentiating finer shades of meaning even in more complex situations. Can understand and participate in technical discussions.",
  },
  {
    title: "Native",
    range: 64000,
    explainer:
      "Has the ability to use language with complete flexibility and intuitiveness. Can recognize different styles and registers as well as slangs.",
  },
];

export default levels;
