import React, { useState, useEffect } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import { Magic } from "react-bootstrap-icons";

function LibraryEditor() {
  const context = React.useContext(AuthContext);

  const { id } = useParams(); // Get the 'id' parameter from the URL
  const navigate = useNavigate();

  const [contentTitle, setContentTitle] = useState("");
  const [contentBody, setContentBody] = useState("");
  const [contentType, setContentType] = useState("story");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [importSuccessMessage, setImportSuccessMessage] = useState("");
  const isEditMode = id !== undefined; // Determine if the component is in edit mode or import mode

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleTitleChange = (event) => {
    setContentTitle(event.target.value);
  };

  const handleBodyChange = (event) => {
    setContentBody(event.target.value);
  };

  const handleContentTypeChange = (event) => {
    setContentType(event.target.value);
  };

  const validateInput = () => {
    if (contentTitle.length < 4 || contentTitle.length > 60) {
      setError("The title must be between 4 and 60 characters.");
      return false;
    }

    if (contentBody.length < 10 || contentBody.length > 500) {
      setError(
        "Describe the situation in a few more words (in less than 500 characters though)."
      );
      return false;
    }

    return true;
  };

  const submitArticle = () => {
    if (!validateInput()) {
      return;
    }

    setError("");
    setImportSuccessMessage("");
    setIsLoading(true);

    const articleData = {
      title: contentTitle,
      topic: contentBody,
      contentType: contentType,
      source: "webapp",
      language: context.getSelectedLanguagePair().language_learning,
    };

    const request = axios.post(`/api/generate-content`, articleData);
    request
      .then((response) => {
        console.log("Generation successful:", response.data);
        setImportSuccessMessage(
          `Content generated successfully. Redirecting you to the editor...`
        );
        setIsLoading(false);
        // TODO: activate this
        // wait for 1 second
        setTimeout(() => {
          navigate("/library/editor/" + response.data.article_id); // Redirect to the editor
        }, 2000);
      })
      .catch((error) => {
        console.error("Error generating content:", error);
        setError(`Error occurred while generating content: ` + error.message);
        setIsLoading(false);
      });
  };

  return (
    <Container
      className="d-flex pt-5 flex-column justify-content-top"
      style={{ minHeight: "70vh" }}
    >
      <h2>Generate Content With AI </h2>
      <p>
        Generate content for every situation you might encounter. And learn it
        as normal.
      </p>
      <Form>
        <Form.Group controlId="formContentImport">
          <Form.Label className="mt-3">
            <strong>Title</strong>
          </Form.Label>
          <Form.Control
            value={contentTitle}
            onChange={handleTitleChange}
            placeholder="Enter title here (max 60 characters)"
          />
          <Form.Label className="mt-3">
            <strong>Describe the situation or topic</strong> (be as specific as
            possible)
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={contentBody}
            onChange={handleBodyChange}
            placeholder="For example: 'I'm going to see the dentist to get a wisdom tooth removed'"
          />
          <Row>
            <Col md={4}>
              {" "}
              {/* Adjust the column size as needed */}
              <Form.Group className="mt-3">
                <Form.Label>
                  <strong>Content type</strong>
                </Form.Label>
                <Form.Check
                  type="radio"
                  id="fake-news"
                  label="Fake news"
                  value="fake news"
                  checked={contentType === "fake news"}
                  onChange={handleContentTypeChange}
                  name="contentType"
                />
                <Form.Check
                  type="radio"
                  id="dialogue"
                  label="Dialogue"
                  value="dialogue"
                  checked={contentType === "dialogue"}
                  onChange={handleContentTypeChange}
                  name="contentType"
                />
                <Form.Check
                  type="radio"
                  id="guideline"
                  label="Guide"
                  value="guide"
                  checked={contentType === "guide"}
                  onChange={handleContentTypeChange}
                  name="contentType"
                />
                <Form.Check
                  type="radio"
                  id="instructions"
                  label="Instructions"
                  value="instructions"
                  checked={contentType === "instructions"}
                  onChange={handleContentTypeChange}
                  name="contentType"
                />
                <Form.Check
                  type="radio"
                  id="poem"
                  label="Poem"
                  value="poem"
                  checked={contentType === "poem"}
                  onChange={handleContentTypeChange}
                  name="contentType"
                />
                <Form.Check
                  type="radio"
                  id="song-lyrics"
                  label="Song lyrics"
                  value="song lyrics"
                  checked={contentType === "song lyrics"}
                  onChange={handleContentTypeChange}
                  name="contentType"
                />
                <Form.Check
                  type="radio"
                  id="story"
                  label="Story"
                  value="story"
                  checked={contentType === "story"}
                  onChange={handleContentTypeChange}
                  name="contentType"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form.Group>
        {error && (
          <Alert variant="danger" className="mt-3 mb-0">
            {error}
          </Alert>
        )}
        {importSuccessMessage && (
          <Alert variant="success" className="mt-3 mb-0">
            {importSuccessMessage}
          </Alert>
        )}
        <Button
          variant="primary"
          onClick={submitArticle}
          disabled={isLoading}
          className="my-3"
        >
          {isLoading ? (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-1"
            />
          ) : (
            <Magic className="me-1 mb-1" />
          )}
          Generate with AI
        </Button>
      </Form>
    </Container>
  );
}

export default LibraryEditor;
