import React from "react";
import {
  Card,
  Row,
  Col,
  Image,
} from "react-bootstrap";

import meelisImage from "../meelis.jpg";

import AppMetaDataContext from "../context/AppMetaDataContext";

function FounderQuote( {language} ) {
  const metaData = React.useContext(AppMetaDataContext);

    return (
      <div>
        <hr />
        <Row style={{ padding: "10% 5% 7% 5%" }}>
          <Col
            xs={{ span: 12, order: 2 }}
            lg={{ span: 3, order: 1 }}
            style={{ marginBottom: "5%" }}
          >
            <Image
              src={meelisImage}
              alt="Meelis Ojasild"
              roundedCircle={true}
              style={{ maxWidth: "150px" }}
            />
          </Col>
          <Col xs={{ span: 12, order: 1 }} lg={{ span: 9, order: 2 }}>
            <Card className="border-0">
              <blockquote className="blockquote">
                <em>
                  {language ? (
                    <>There is this huge misconception in language learning that you can become fluent by learning a few made up sentences and studying grammar. Did you acquire your mother tongue that way? Of course not.<br /><br />You need lots of reading and listening to become fluent. And it needs to be interesting content. There's no way around that.</>
                  ) : (
                    <>
                      “After a year of learning Spanish with Duolingo I felt
                      stuck. Theoretically I was supposed to be on B1 level. But
                      I couldn't read or listen to anything practical.
                      <br /> <br />After less than 3 months of using Lingo Champion, I finally managed to read news articles without any problems. And I've learned so much about the culture of Spanish-speaking countries in the process.”
                    </>
                  )}
                </em>
              </blockquote>
              <p>- Meelis Ojasild, Founder of {metaData.appName}</p>
            </Card>
          </Col>
        </Row>

        <hr />
      </div>
    );
}

export default FounderQuote;
