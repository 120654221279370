import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const GridLayout = () => {
  return (
    <Container fluid>
      <Row style={{ minHeight: "100vh" }}>
        {/* First Column */}
        <Col className="d-flex flex-column">
          <Row>
            <Col xs={2} md={1} className="d-flex justify-content-center">
              X
            </Col>
            <Col xs={8} md={10}>
              Progress bar
            </Col>
            <Col xs={2} md={1} className="d-flex justify-content-center">
              ?
            </Col>
          </Row>
          <Row className="flex-grow-1">
            {/* Set "Prev" and "Next" to auto width and "Content 2" to maximum */}
            <Col xs="auto" className="d-flex align-items-center">
              Prev
            </Col>
            <Col className="flex-grow-1">Content 2</Col>{" "}
            {/* This column takes maximum width */}
            <Col xs="auto" className="d-flex align-items-center">
              Next
            </Col>
          </Row>
          <Row>
            <Col>Content 3</Col>
          </Row>
        </Col>

        {/* Second Column - Grows to take remaining space */}
        <Col md>
          <p>Content in middle column</p>
        </Col>
      </Row>
    </Container>
  );
};

export default GridLayout;
